/**
 * ImageWrapper
 */

import styled from 'styled-components';

const ImageWrapper = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 30px;

  overflow: hidden;

  display: inline-block;
  vertical-align: middle;
  line-height: normal;

  img {
    width: 100%;
  }

  margin-right: 20px;

  @media (min-width: 768px) {
    margin-right: 30px;
  }

`;

export default ImageWrapper;
