/**
*
* Contents
*
*/

import styled from 'styled-components';

const Contents = styled.div`
  background-color: rgba(255, 255, 255, 1);
  opacity: 1;
  margin: 200px auto; /* 15% from the top and centered */
  padding: 46px;
  border: 1px solid ${(props) => props.theme.mediumGray};
  width: 60%; /* Could be more or less, depending on screen size */
  max-width: 700px;

  position: relative;
  -webkit-background-clip: padding-box;
          background-clip: padding-box;
  border: 1px solid ${(props) => props.theme.lightGray};
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 6px;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgba(255, 255, 255, .5);
          box-shadow: 0 3px 9px rgba(255, 255, 255, .5);
`;

export default Contents;
