/* eslint-disable max-len */
/*
 * Explore Messages
 *
 * This contains all the text for the Explore component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  nurtureYourself: {
    defaultMessage: 'Nurture Yourself',
    id: 'app.components.Values.nurtureYourself',
    modalMessage: 'The best work is built on a foundation of self-care. Don’t be afraid to get your needs met.',
  },
  scaleYourMountain: {
    defaultMessage: 'Scale Your Mountain',
    id: 'app.containers.Values.scaleYourMountain',
    modalMessage: 'We explore with intention and ask hard questions of each other, to find work that matters to us.',
  },
  digDeepWells: {
    defaultMessage: 'Dig Deep Wells',
    id: 'app.containers.Values.digDeepWells',
    modalMessage: 'By showing up authentically and investing in meaningful relationships, we forge bonds that will last beyond our time at SPC.',
  },
  ownOurBetterFuture: {
    defaultMessage: 'Own Our Better Future',
    id: 'app.containers.Values.ownOurBetterFuture',
    modalMessage: [
      'By immersing yourself and taking initiative, you leave a legacy for those who will walk in your steps.',
      'SPC is yours now: you don’t need to ask for permission!',
    ],
  },
});
