/**
*
* ShapesWrapper
* Wrapper for shapes
*/

import styled from 'styled-components';


const ShapesWrapper = styled.div`
  position: relative;
  text-align: right;
  height: 0;
  display: ${(props) => props.noMobile ? 'none' : 'block'};

  top: -80px;
  right: -40px;

  @media (min-width: 768px) {
    top: -120px;
    right: -50px;
    display: ${(props) => props.noDesktop ? 'none' : 'block'};
  }

  @media (min-width: 992px) {
    top: -350px;
    right: -80px;
  }
`;

export default ShapesWrapper;
