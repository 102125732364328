/* eslint-disable max-len */
/*
 * Community Messages
 *
 * This contains all the text for the Community container.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    defaultMessage: 'Community',
    id: 'app.containers.Community.header',
  },
  title: {
    defaultMessage: '“The real life of the Commons doesnʼt come from its beautiful office or amazing location, but from the jovial intensity of its members. Everyone is an open book, filled with advice, ideas, and an eagerness to help. The result is a community of smart, motivated, helpful people exploring new paths together.”',
    id: 'app.containers.Community.title',
  },
  byline: {
    defaultMessage: '— David Kosslyn, SPC member',
    id: 'app.containers.Community.byline',
  },
});
