/* eslint-disable max-len */
/*
 * NotFound Messages
 *
 * This contains all the text for the NotFound container.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    defaultMessage: 'Nothing\'s here. Nothing to see.',
    id: 'app.containers.NotFound.header',
  },
});
