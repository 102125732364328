/**
 * PageWrapper
 */

import styled from 'styled-components';

const PageWrapper = styled.div`
  max-width: 663px;
  margin: auto;
  position: block-inline;

  @media (min-width: 992px) {
    max-width: 750px;
  }
  @media (min-width: 1200px) {
    max-width: 850px;
  }
`;

export default PageWrapper;
