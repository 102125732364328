/**
 * TeamDiv
 */

import styled from 'styled-components';

const TeamDiv = styled.div`
`;

export default TeamDiv;
