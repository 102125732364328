/**
*
* Close
*
*/

import React from 'react';
import PropTypes from 'prop-types';

// local
import Wrapper from './Wrapper';

/**
 * The Close component
 * @return {Function} The JSX component for a Close
 */
function Close({ onClick }) {
  return (
    <Wrapper onClick={onClick} >&times;</Wrapper>
  );
}

Close.propTypes = {
  /** Callback that occurs on click */
  onClick: PropTypes.func,
};

export default Close;
