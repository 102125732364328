/* eslint-disable max-len */
/*
 * Events Messages
 *
 * This contains all the text for the Events container.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    defaultMessage: 'Events',
    id: 'app.containers.Events.header',
  },
  past: {
    defaultMessage: 'Watch & Read',
    id: 'app.containers.Events.past',
  },
  title: {
    defaultMessage: 'We host lectures, brainstorming events, and workshops on topics members are exploring. Since our founding, we’ve discussed AI, cryptocurrency, astrophysics, and more.',
    id: 'app.containers.Events.title',
  },
  upcoming: {
    defaultMessage: 'Selected Upcoming Events',
    id: 'app.containers.Events.upcoming',
  },
});
