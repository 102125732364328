/* eslint-disable max-len */
/*
 * Newsletter Messages
 *
 * This contains all the text for the Newsletter component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    defaultMessage: 'Stay in The Loop',
    id: 'app.components.Newsletter.header',
  },
  submit: {
    defaultMessage: 'Get Updates',
    id: 'app.containers.Newsletter.submit',
  },
});
