/**
*
* FooterNav
*
*/

import React from 'react';
// import PropTypes from 'prop-types';

// components
import NavLink from '../NavLink';

// local
//import CollapseWrapper from './CollapseWrapper';
import Li from './Li';
import Ul from './Ul';
//import Modal from './Modal';
import Wrapper from './Wrapper';

/**
 * The FooterNav component
 * @return {Function} The JSX component for a FooterNav
 */
class FooterNav extends React.Component {
  state = { hidden: true }

  paths = [{
    path: '/story',
    title: 'Our Story',
    external: false,
  }, {
    path: '/community',
    title: 'Community',
    external: false,
  }, {
    path: '/fund',
    title: 'Fund',
    external: false,
  }, {
    path: '/events',
    title: 'Events',
    external: false,
  }, {
    path: '/faq',
    title: 'FAQ',
    external: false,
  }, {
    path: '/apply',
    title: 'Apply',
    external: false,
  }, {
    path: 'https://jobs.southparkcommons.com',
    title: 'Jobs',
    target: '_blank',
    external: true,
  }]

  scrollToTop = () => {
    window.scroll({top: 0,  left: 0, behavior: 'auto'});
  }

  render() {

    return (
        <Wrapper style={{textAlign:"center"}}>
        <Ul>
          {this.paths.map((path) => (
            <Li key={path.path}><NavLink close={!path.external ? this.scrollToTop : () => {}} for_footer={true} {...path} /></Li>
          ))}
        </Ul>
        </Wrapper>
    );
  }
}

FooterNav.propTypes = {
};

export default FooterNav;
