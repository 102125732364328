/**
*
* Values
*
* Values SPC
*/

import React from 'react';


// components
import Col4 from '../Col4';
import Line from '../Line';
import Preview from '../Preview';

// local
import digDeepWells from './digDeepWells.png';
import scaleYourMountain from './scaleYourMountain.png';
import nurtureYourself from './nurtureYourself.png';
import ownOurBetterFuture from './ownOurBetterFuture.png';
import messages from './messages';
import Wrapper from './Wrapper';

/**
 * Explore SPC
 * @return {Function} The JSX component for a Explore
 */
function Explore() {
  return (
    <Wrapper>
      <Line padding="10px 0" />
      <Col4 noLeftMargin>
        <Preview
          header={messages.nurtureYourself}
          img={nurtureYourself}
        />
      </Col4>
      <Col4>
        <Preview
          header={messages.scaleYourMountain}
          img={scaleYourMountain}
        />
      </Col4>
      <Col4>
        <Preview
          header={messages.digDeepWells}
          img={digDeepWells}
        />
      </Col4>
      <Col4 noRightMargin>
        <Preview
          header={messages.ownOurBetterFuture}
          img={ownOurBetterFuture}
        />
      </Col4>

    </Wrapper>
  );
}


Explore.propTypes = {
};

export default Explore;
