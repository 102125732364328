/**
*
* TextWrapper
* Wraps tex
*/

import styled from 'styled-components';


const TextWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding-top: 30px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100%;

  > * {
    line-height: 1.6 !important;
    opacity: 0.8;
    font-weight: normal;
  }

  @media (min-width: 768px) {
    width: 460px;
  }

  @media (min-width: 992px) {
    width: 650px;
  }

`;

export default TextWrapper;
