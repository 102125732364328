/**
*
* InfoWrapper
* Wraps info
*/

import styled from 'styled-components';


const InfoWrapper = styled.div`
  text-align: center;
  padding-bottom: 20px;
`;

export default InfoWrapper;
