/* eslint-disable max-len */
/*
 * Fund Messages
 *
 * This contains all the text for the Fund container.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    defaultMessage: 'Who We Are',
    id: 'app.containers.Fund.header',
  },
  title: {
    defaultMessage: 'The South Park Commons Fund is for entrepreneurs, for hackers, for adventurers—for people who dream big and build big too.',
    id: 'app.containers.Fund.title',
  },
    technology: {
	defaultMessage: 'South Park Commons is a community of builders, tinkerers, and domain experts that challenge you, encourage you, help you build and validate your ideas. We believe good ideas very rarely come about in isolation. We are the anti-incubator, a safe zone that promotes judgement-free exploration and development of new ideas.',
    id: 'app.containers.Fund.technology',
  },
  entrepreneurs: {
    defaultMessage: 'We like to partner with entrepreneurs early in their journey and look to invest in the first round of financing. We are entrepreneurs ourselves who have shepherded a company from its birth to a successful acquisition, so we understand the journey that founders take. We’ve also scaled companies like Facebook and Dropbox from a handful of employees to hundreds of millions of users, so we understand how to operate a business on a global scale. Our goal is to leverage our collective experience to help our companies in any way possible on their respective journeys.',
    id: 'app.containers.Fund.entrepreneurs',
  },
  believe: {
    defaultMessage: 'We believe technology is going to fundamentally transform the day-to-day life of every single person in the world and we are excited to partner with our companies to be part of that movement.',
    id: 'app.containers.Fund.believe',
  },
  team: {
    defaultMessage: 'Team',
    id: 'app.containers.Fund.team',
  },
  companies: {
    defaultMessage: 'Companies',
    id: 'app.containers.Fund.companies',
  },
  contactus: {
    defaultMessage: 'Have a great idea? Let’s talk.',
    id: 'app.containers.Fund.contactus',
  },
  tellusmore: {
    defaultMessage: 'Tell us more',
    id: 'app.containers.Fund.tellusmore',
  },
  tellusmorelink: {
    defaultMessage: 'here',
    id: 'app.containers.Fund.tellusmorelink',
  },
});
