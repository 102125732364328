/**
*
* StyledNoProfile
*
*/

import styled from 'styled-components';

const StyledNoProfile = styled.div`
  color: ${(props) => props.theme.gray};
  font-size: 12px;
`;

export default StyledNoProfile;
