/**
*
* HomeWrapper
*
*/

import styled from 'styled-components';

const StyledWrapper = styled.div`
  display: block;
  margin: 0 auto;

  max-width: 100%;

  @media (min-width: 768px) {
    max-width: 504px;
  }

  @media (min-width: 992px) {
    max-width: 758px;
  }
`;

export default StyledWrapper;
