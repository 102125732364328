/**
*
* Col1
*
*/

import styled from 'styled-components';

const Col1 = styled.div`
  width: 100%;
  margin: 10px 0;

  @media (min-width: 768px) {
    width: calc(100% - 20px);
    margin: 10px 10px;
  }
`;

export default Col1;
