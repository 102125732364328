/**
*
* Footer
*
*/

import React from 'react';
import { FormattedMessage } from 'react-intl';

// components
import IconLink from '../IconLink';
import FooterNav from '../FooterNav';

// local
import CopyrightWrapper from './CopyrightWrapper';
import StyledLocationA from './StyledLocationA';
import StyledSpan from './StyledSpan';
import StyledLinks from './StyledLinks';
import messages from './messages';

/**
 * The Footer component
 * @return {Function} The JSX component for a Footer
 */
function Footer() {
  return (
    <div>
      <StyledLinks>
        <StyledSpan>
          <IconLink
            href="http://www.facebook.com/southpkcommons"
            type="fa fa-facebook social-logo"
          />
        </StyledSpan>
        <StyledSpan>
          <IconLink
            href="http://www.twitter.com/southpkcommons"
            type="fa fa-twitter social-logo"
          />
        </StyledSpan>
        <StyledSpan>
          <IconLink
            href="https://vimeo.com/southparkcommons"
            type="fa fa-vimeo social-logo"
          />
        </StyledSpan>
        <StyledSpan>
          <IconLink
            href="https://www.instagram.com/southparkcommons"
            type="fa fa-instagram social-logo"
          />
        </StyledSpan>
        <StyledSpan>
          <IconLink
            href="https://www.github.com/southparkcommons"
            type="fa fa-github social-logo"
          />
        </StyledSpan>
        <StyledSpan>
          <IconLink
            href="https://www.youtube.com/channel/UCMR-rPSUI34DRQXUkvFuIUQ"
            type="fa fa-youtube social-logo"
          />
        </StyledSpan>
      </StyledLinks>
      <FooterNav />
      <StyledLocationA href="https://goo.gl/maps/syMLv5GLcNF2" target="_new">
        <div><FormattedMessage {...messages.header} /></div>
        <div><FormattedMessage {...messages.address} /></div>
      </StyledLocationA>
      <CopyrightWrapper>
        <FormattedMessage {...messages.copyright} values={{ copyright: <span>&copy;</span> }} />
      </CopyrightWrapper>
    </div>
  );
}


/**
 *  <StyledSpan>
          <IconLink
            href="http://www.linkedin.com/company/18169310"
            type="fa fa-linkedin social-logo"
          />
        </StyledSpan>
 */

Footer.propTypes = {
};

export default Footer;
