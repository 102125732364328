/**
 *
 * Community
 *
 * Info about the spc community
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';

// components
import ApplyLink from '../../components/ApplyLink';
import BigText from '../../components/BigText';
import H2 from '../../components/H2';
import H3 from '../../components/H3';
import Intro from '../../components/Intro';
import InnerDiv from '../../components/InnerDiv';
import PageFooter from '../../components/PageFooter';
import OuterDiv from '../../components/OuterDiv';
import UpArrow from '../../components/UpArrow';

// containers
import Members from '../Members';
import Testimonials from '../Testimonials';
import Companies from '../Companies';

// utils

// local
import messages from './messages';
import Wrapper from './Wrapper';

/**
 * The Community component
 * @return {Function} The JSX component for a Community
 */
export function Community() {
  return (
    <Wrapper>
       <Helmet>
          <title>Community | South Park Commons</title>
          <meta name="description" content="Meet The South Park Commons members, alumni, and companies." />
          <meta property="og:title" content="Community | South Park Commons" />
          <meta property="og:description" content="Meet The South Park Commons members, alumni, and companies." />
          <meta name="twitter:title" content="Community | South Park Commons" />
          <meta name="twitter:description" content="Meet The South Park Commons members, alumni, and companies." />
      </Helmet>
      <Intro>
        <H2><FormattedMessage {...messages.header} /></H2>
        <BigText>
          <FormattedMessage {...messages.title} />
        </BigText>
        <H3><FormattedMessage {...messages.byline} /></H3>
      </Intro>
      <ApplyLink />
      <OuterDiv margin>
        <UpArrow />
        <InnerDiv height="120px" right />
        <InnerDiv />
      </OuterDiv>
      <Members />
      <Companies />
      <Testimonials />
      <PageFooter noTop />
    </Wrapper>
  );
}


Community.propTypes = {
};

export default Community;
