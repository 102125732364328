/**
 * ImageWrapper
 */

import styled from 'styled-components';

const ImageWrapper = styled.div`
  background-size: cover;
  background-position: -10%;
  border-radius: 164px;
  max-width: 241px;
  margin: 0 auto;
  height: 130px;
  width: 130px;
  background-image: url(${(props) => props.image});
`;

export default ImageWrapper;
