/**
*
* CopyrightWrapper
* Wrapper for copyright
*/

import styled from 'styled-components';


const CopyrightWrapper = styled.div`
  text-align: center;
  font-size: .8em;
`;

export default CopyrightWrapper;
