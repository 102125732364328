/**
 * SummaryWrapper
 */

import styled from 'styled-components';

// components

const SummaryWrapper = styled.div`
    float: right;
    text-align: left;
    width: 80%;
    font-size: 12px;
    margin-bottom: 15px;

  @media (min-width: 768px) {
    font-size: 14px;
  }

  @media (min-width: 992px) {
    font-size: 18px;
  }
`;

export default SummaryWrapper;
