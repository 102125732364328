/* eslint-disable require-jsdoc */
/**
 *
 * Fund
 *
 * Info about the spc fund
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';

// components
import FundLink from '../../components/FundLink';
import Intro from '../../components/Intro';
import BigText from '../../components/BigText';
import UpArrow from '../../components/UpArrow';
import OuterDiv from '../../components/OuterDiv';
import InnerDiv from '../../components/InnerDiv';
import StyledText from '../../components/StyledText';
import ImgFull from '../../components/ImgFull';
import Dot from '../../components/Dot';
import A from '../../components/A';

// containers
import TeamMember from '../TeamMember';

// utils

// local
import messages from './messages';
import Wrapper from './Wrapper';
import InnerWrapper from './InnerWrapper';
import design1 from './design1.png';

/**
 * The Fund component
 * @return {Function} The JSX component for a Fund
 */
// eslint-disable-next-line no-empty-pattern
export function Fund({ }) {
  return (
    <Wrapper>
      <Helmet>
          <title>Fund | South Park Commons</title>
          <meta name="description" content="The South Park Commons Fund is for entrepreneurs, for hackers, for adventurers—for people who dream big and build big too." />
          <meta property="og:title" content="Fund | South Park Commons" />
          <meta property="og:description" content="The South Park Commons Fund is for entrepreneurs, for hackers, for adventurers—for people who dream big and build big too." />
          <meta name="twitter:title" content="Fund | South Park Commons" />
          <meta name="twitter:description" content="The South Park Commons Fund is for entrepreneurs, for hackers, for adventurers—for people who dream big and build big too." />
      </Helmet>
      <InnerWrapper>
        <Intro>
          <BigText>
            <div><FormattedMessage {...messages.header} /></div>
            <OuterDiv />
            <OuterDiv />
            <FormattedMessage {...messages.title} />
          </BigText>
        </Intro>
        <FundLink />
        <OuterDiv>
          <UpArrow />
          <InnerDiv bottom />
          <InnerDiv noDesktop left />
          <InnerDiv noMobile left>
            <StyledText noIndent><FormattedMessage {...messages.technology} /></StyledText>
          </InnerDiv>
          <InnerDiv noDesktop full left>
            <StyledText noIndent><FormattedMessage {...messages.technology} /></StyledText>
          </InnerDiv>
        </OuterDiv>
        <OuterDiv noDesktop>
          <InnerDiv bottom top height="100px" />
          <InnerDiv left height="100px" />
        </OuterDiv>
        <OuterDiv noMobile left>
          <InnerDiv bottom height="524px" >
            <StyledText noIndent><FormattedMessage {...messages.entrepreneurs} /></StyledText>
          </InnerDiv>
          <InnerDiv>
            <ImgFull height="386px" src={design1} alt="design-1" />
          </InnerDiv>
        </OuterDiv>

        <OuterDiv noDesktop left>
          <InnerDiv full>
            <StyledText noIndent><FormattedMessage {...messages.entrepreneurs} /></StyledText>
          </InnerDiv>
        </OuterDiv>
        <OuterDiv noDesktop>
          <InnerDiv top height="100px" />
          <InnerDiv left height="100px" />
        </OuterDiv>
        <OuterDiv noMobile>
          <InnerDiv height="300px" right></InnerDiv>
          <InnerDiv height="300px" ></InnerDiv>
        </OuterDiv>
        <OuterDiv>
          <InnerDiv full>
            <BigText alignLeftMobile>
              <FormattedMessage {...messages.believe} />
            </BigText>
          </InnerDiv>
        </OuterDiv>
        <TeamMember />
        <OuterDiv top />
        <OuterDiv>
          <InnerDiv height="120px" right />
          <InnerDiv />
          <Dot />
        </OuterDiv>
        <OuterDiv>
          <InnerDiv full>
            <BigText>
              <div id="apply"><FormattedMessage {...messages.contactus} /></div>
              <FormattedMessage {...messages.tellusmore} />{' '}
              <A href="https://airtable.com/shrPQmOwipjUjCrUe">
                <span style={{ fontSize: 36 }}>
                  <FormattedMessage {...messages.tellusmorelink} />
                </span>
              </A>
.
            </BigText>
          </InnerDiv>
        </OuterDiv>
      </InnerWrapper>
    </Wrapper>
  );
}

Fund.propTypes = {
};

export default Fund;
