/**
*
* Newsletter
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

// components
import BigText from '../../components/BigText';
import Col1 from '../../components/Col1';
import ErrorMessage from '../../components/ErrorMessage';
import InputForm from '../../components/InputForm';
import H3 from '../../components/H3';

// local
import messages from './messages';
import Wrapper from './Wrapper';

class Newsletter extends React.Component {
  state = { error: false, success: false, email: '' }

  onSubmit = (e) => {
    const { email } = this.state;
    if (!email) return null;
    e.preventDefault();

    try {
      return this.props.mutate({ variables: { email } })
        .then(({ data: { subscribe } }) => this.setState({
          success: subscribe === 'true',
          error: subscribe !== 'true' ? subscribe : false,
        }));
    } catch (error) {
      return this.setState({ error });
    }
  }

  render() {
    const { error, success, email } = this.state;
    return (
      <Wrapper>
        <BigText><FormattedMessage {...messages.header} /></BigText>
        <InputForm
          onSubmit={this.onSubmit}
          placeholder="Email"
          name="EMAIL"
          type="email"
          value={email}
          onChange={(e) => this.setState({ email: e.target.value })}
          submitMessage={messages.submit}
          required
          hideStar
        />
        <ErrorMessage hidden={!error}>
          {error}
        </ErrorMessage>
        <Col1 hidden={!success}>
          <br />
          <H3>Great! We will subscribe you.</H3>
        </Col1>
      </Wrapper>
    );
  }
}

Newsletter.propTypes = {
  mutate: PropTypes.func.isRequired,
};

export default graphql(gql`
  mutation NewletterMutation($email: String!) {
    subscribe(email: $email)
  }
`)(Newsletter);
