/**
*
* H4
* H4
*/

import styled from 'styled-components';

const H4 = styled.h4`
  font-weight: 300;
  font-size: 14px;
  color: ${(props) => props.theme.gold};
  letter-spacing: 0.06px;
  text-align: ${(props) => props.alignCenter ? 'center' : 'left'};
  line-height: 16px;

  @media (min-width: 768px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

export default H4;
