/**
*
* Testimonials
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { sampleSize } from 'lodash';
import { FormattedMessage } from 'react-intl';

// components
import H2 from '../../components/H2';
import Section from '../../components/Section';
import Testimonial from '../../components/Testimonial';
import LoadingIndicator from '../../components/LoadingIndicator';
import ErrorMessage from '../../components/ErrorMessage';
import Line from '../../components/Line';

// local
import TestimonialDiv from './TestimonialDiv';
import messages from './messages';
import Arrow from './Arrow';
import FadeCover from './FadeCover';

class Testimonials extends React.Component {
  state = { hidden: true }
  threeTestimonials = [];

  render() {
    const { hidden } = this.state;
    const { data: { loading, testimonials, error } } = this.props;
    let content;

    // Loading testimonials from backend
    if (loading) content = <LoadingIndicator />;
    // An error occurred while loading testimonials
    else if (error) content = <ErrorMessage >{error.message}</ErrorMessage>;
    // Testimonials were loaded
    else {
      // Load random testimonials
      if (this.threeTestimonials.length === 0) {
        this.threeTestimonials = sampleSize(testimonials.filter((t) => t.Include), 3);
      }

      if (this.threeTestimonials.length) {
        content = (
          <Section id="testimonials">
            <H2>
              <FormattedMessage {...messages.header} />
            </H2>
            <Line />
            <TestimonialDiv hidden={hidden}>
              {this.threeTestimonials.map((testimonial) => (
                <Testimonial key={testimonial.Name} {...testimonial} />
              ))}
              <Arrow hidden={hidden} onClick={() => this.setState({ hidden: !hidden })} />
              <FadeCover hidden={hidden} />
            </TestimonialDiv>
            <Line />
          </Section>
        );
      }
    }

    return (
      <div>
        {content}
      </div>
    );
  }
}

Testimonials.propTypes = {
  /** The graphql request data for testimonials */
  data: PropTypes.object.isRequired,
};

export default graphql(gql`
  query TestimonialsQuery {
    testimonials {
      Name,
      Quotation,
      Include,
      Title,
      Headshot,
      SecondaryHeadshot
    }
  }
`)(Testimonials);
