import styled from 'styled-components';

const SmallWrapper = styled.div`
  width: 15px;
  height: 15px;
  position: absolute;
  margin: 6px 8px;
  float: left;
  display: inline-block;
`;

export default SmallWrapper;
