/**
*
* OuterDiv
* Outer div for home page
*/

import styled from 'styled-components';


const OuterDiv = styled.div`
  box-sizing: border-box;
  position:relative;
  width:100%;
  text-align: center;
  margin-top: ${(props) => props.margin ? '20px' : '0'};
  min-height:${(props) => (props.minHeight ? props.minHeight : (props.empty ? '70px' : '20px'))};
  padding: ${(props) => props.padding ? props.padding : '0'};

  border-top: ${(props) => props.top ? '1px solid blue' : 'none'};
  border-bottom: ${(props) => props.bottom ? '1px solid blue' : 'none'};
  border-left: ${(props) => props.left ? '1px solid blue' : 'none'};
  border-right: ${(props) => props.right ? '1px solid blue' : 'none'};

  display: ${(props) => props.noMobile ? 'none' : 'block'};

  @media only screen and (min-width: 768px) {
    display: ${(props) => props.noDesktop ? 'none' : 'block'};
  })
`;

export default OuterDiv;
