/**
*
* ImgWrapper
* Wrapper for image
*/

import styled from 'styled-components';


const ImgWrapper = styled.div`
  z-index: -1;
  position: relative;
  left: -30px;
  top: 30px;
  text-align: left;
  align-content: left;
  display: ${(props) => props.noMobile ? 'none' : 'block'};

  @media (min-width: 768px) {
    img {
      width: 304px;
    }
    left: 0;
    top: -70px;
    display: ${(props) => props.noDesktop ? 'none' : 'block'};
  }

  @media (min-width: 992px) {
    img {
      width: 350px;
    }
    left: -60px;
    top: -103px;
    display: ${(props) => props.noDesktop ? 'none' : 'block'};
  }
`;

export default ImgWrapper;
