/**
*
* InputForm
*
* A form that is a single input
*/

import React from 'react';
import PropTypes from 'prop-types';

// components
import Input from '../Input';

// local
import Wrapper from './Wrapper';

/**
 * A form that is a single input
 * @return {Function} The JSX component for a InputForm
 */
function InputForm({ onSubmit, submitMessage, ...inputProps }) {
  return (
    <Wrapper onSubmit={onSubmit}>
      <Input {...inputProps} onSubmit={onSubmit} submitMessage={submitMessage} />
    </Wrapper>
  );
}


InputForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitMessage: PropTypes.object,
};

export default InputForm;
