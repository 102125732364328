/**
*
* NotFoundWrapper
*
*/

import styled from 'styled-components';

const StyledWrapper = styled.div`
  text-align: center;
`;

export default StyledWrapper;
