/**
*
* Testimonial
*
*/

import React from 'react';
import PropTypes from 'prop-types';

import StyledText from '../StyledText';

// local
import NameWrapper from './NameWrapper';
import StyledH3 from './StyledH3';
import Wrapper from './Wrapper';
import ImageWrapper from './ImageWrapper';
import TitleWrapper from './TitleWrapper';

/**
 * The Testimonial component
 * @return {Function} The JSX component for a Testimonial
 */
function Testimonial({
  Name, Quotation, Headshot, Title, SecondaryHeadshot,
}) {
  return (
    <Wrapper>
      <TitleWrapper>
        <ImageWrapper>
          <img src={Headshot} alt={Name} />
        </ImageWrapper>
        {
          SecondaryHeadshot != null
            ? <ImageWrapper>
              <img src={SecondaryHeadshot} alt={Name} />
            </ImageWrapper>
            : null
        }
        <NameWrapper>
          <StyledH3 justify="left" selected>{Name}</StyledH3>
          <StyledH3 justify="left" selected>{Title}</StyledH3>
        </NameWrapper>
      </TitleWrapper>
      <StyledText noIndent style={{ paddingTop: '20px' }}>
        "{Quotation}"
      </StyledText>
    </Wrapper>
  );
}

Testimonial.propTypes = {
  /** The headshot of the testimonial */
  Headshot: PropTypes.string.isRequired,
  /** The name of the testimonial */
  Name: PropTypes.string.isRequired,
  /** The testimonial itself */
  Quotation: PropTypes.string.isRequired,
  /** The title of the person */
  Title: PropTypes.string,
};

export default Testimonial;
