/**
 * InnerWrapper
 */

import styled from 'styled-components';

const InnerWrapper = styled.div`
  position:relative;

  width: 96px;
  min-height: 96px;

  overflow: hidden;

  img {
    width: 100%;
  }

  display: block;
  margin: 0 auto;
`;

export default InnerWrapper;
