/**
*
* SubmitWrapper
* Wrapper for submit
*/

import styled from 'styled-components';


const SubmitWrapper = styled.span`
  display: inline-block;
  text-align: right;
  align-content: right;
  position: absolute;
  top: -3px;
  right: 0px;
`;

export default SubmitWrapper;
