/* eslint-disable max-len */
/*
 * Blog Messages
 *
 * This contains all the text for the Blog container.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    defaultMessage: 'This is the Blog container!',
    id: 'app.containers.Blog.header',
  },
});
