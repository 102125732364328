/* eslint-disable camelcase */
/**
*
* TeamMember
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { FormattedMessage } from 'react-intl';

// components
import LoadingIndicator from '../../components/LoadingIndicator';
import ErrorMessage from '../../components/ErrorMessage';
import FundMember from '../../components/FundMember';
import OuterDiv from '../../components/OuterDiv';
import InnerDiv from '../../components/InnerDiv';
import BigText from '../../components/BigText';

// local
import messages from '../Fund/messages';
import TeamDiv from './TeamDiv';

// eslint-disable-next-line react/prefer-stateless-function
class TeamMember extends React.Component {
  render() {
    const { data: { loading, fundteammates, error } } = this.props;
    let content;

    // Loading teammembers from backend
    if (loading) content = <LoadingIndicator />;
    // An error occurred while loading teammembers
    else if (error) content = <ErrorMessage>{error.message}</ErrorMessage>;
    // teammembers were loaded
    else if (Object.values(fundteammates).length > 0) {
      content = (
        <div>
          <OuterDiv>
            <InnerDiv height="200px" right></InnerDiv>
            <InnerDiv height="200px" ></InnerDiv>
          </OuterDiv>
          <OuterDiv>
            <InnerDiv full>
              <BigText>
                <FormattedMessage {...messages.team} />
              </BigText>
            </InnerDiv>
          </OuterDiv>
          <OuterDiv top>
            <InnerDiv full>
              <TeamDiv>
                {
                  fundteammates.map((fundteammate) => (
                    <FundMember key={fundteammate.Name} {...fundteammate} />
                  ))
                }
              </TeamDiv>
            </InnerDiv>
          </OuterDiv>
        </div>
      );
    }

    return (
      <div>
        {content}
      </div>
    );
  }
}


TeamMember.propTypes = {
  /** The graphql request for team members */
  data: PropTypes.object.isRequired,
};

export default graphql(gql`
  query FundTeamMatesQuery {
    fundteammates {
      Name,
      Summary1,
      Summary2,
      MemberPhoto
    }
  }
`)(TeamMember);
