/**
*
* Dot
* A dot
*/

import styled from 'styled-components';


const Dot = styled.div`
  /* Fill 9: */
  background: ${(props) => props.theme.blue};
  width: 6px;
  height: 6px;
  border-radius: 3px;
  margin: 0 50%;
  transform: translate(-3.5px, 0);
`;

export default Dot;
