/**
*
* EventWrapper
*
*/

import styled from 'styled-components';

const Wrapper = styled.div`
  ${(props) => props.hover ? `
    &:hover * {
      color: ${props.theme.blue};
    }
  ` : ''}
`;

export default Wrapper;
