import React from 'react';
import PropTypes from 'prop-types';

import Circle from './Circle';
import Wrapper from './Wrapper';
import SmallWrapper from './SmallWrapper';

/**
 * Display a loading indicator
 * @param {Boolean} options.hidden - If the indicator should be hidden
 * @param {Boolean} options.small - Should we have a tiny indicator
 * @return {Function} The JSX component for the LoadingIndicator
 */
function LoadingIndicator({ hidden, small }) {
  const Wrap = small ? SmallWrapper : Wrapper;
  return (
    <span hidden={hidden}>
      <Wrap>
        <Circle />
        <Circle delay={-1.1} rotate={30} />
        <Circle delay={-1} rotate={60} />
        <Circle delay={-0.9} rotate={90} />
        <Circle delay={-0.8} rotate={120} />
        <Circle delay={-0.7} rotate={150} />
        <Circle delay={-0.6} rotate={180} />
        <Circle delay={-0.5} rotate={210} />
        <Circle delay={-0.4} rotate={240} />
        <Circle delay={-0.3} rotate={270} />
        <Circle delay={-0.2} rotate={300} />
        <Circle delay={-0.1} rotate={330} />
      </Wrap>
    </span>
  );
}

LoadingIndicator.propTypes = {
  /** If the indicator should be hidden */
  hidden: PropTypes.bool,
  /** Should we have a tiny indicator */
  small: PropTypes.bool,
};

export default LoadingIndicator;
