/**
 * StyledSpan
 */

import styled from 'styled-components';

const StyledSpan = styled.span`
  margin-left: 16px;
  margin-right: 16px;
  display: inline-block;
  font-size: 14px;
`;

export default StyledSpan;
