/**
*
* Wrapper
*
*/

import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  margin-top: ${(props) => props.moveTop ? props.moveTop : 0};
`;

export default Wrapper;
