/* eslint-disable max-len */
/*
 * Testimonials Messages
 *
 * This contains all the text for the Testimonials container.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    defaultMessage: 'See Also:',
    id: 'app.containers.Press.header',
  },
  article1: {
    defaultMessage: 'South Park Commons Raises Fund',
    id: 'app.containers.Press.article1',
  },
  article2: {
    defaultMessage: 'South Park Commons Fills a Hole in the SF Tech Landscape',
    id: 'app.containers.Press.article2',
  },
});
