/**
*
* Wrapper
*
*/

import styled from 'styled-components';

const Wrapper = styled.div`
  position: block;
  width: 100%;
  padding-bottom: 10px;

  @media (min-width: 768px) {
    position: relative;
    width: 663px;
    padding-right: 45px;
    padding-left: 45px;
    margin-right: auto;
    margin-left: auto;
    border-bottom: none;
    padding-bottom: 50px;
  }
  @media (min-width: 992px) {
    width: 970px;
  }
  @media (min-width: 1200px) {
    width: 1055px;
  }
`;

export default Wrapper;
