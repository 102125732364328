/**
 * TestimonialDiv
 */

import styled from 'styled-components';

const TestimonialDiv = styled.div`
  ${(props) => props.hidden ? 'height: 300px;' : ''}
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow: hidden;
  position: relative;
`;

export default TestimonialDiv;
