/**
*
* Modal
*
*/

import React from 'react';
import PropTypes from 'prop-types';

// components
import Close from '../Close';

// local
import Wrapper from './Wrapper';
import Contents from './Contents';

class Modal extends React.Component {
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.closeModal();
    }
  }

  render() {
    const {
      closeModal, hidden, children,
    } = this.props;

    return (
      <Wrapper hidden={hidden}>
        <Contents innerRef={this.setWrapperRef}>
          <Close onClick={closeModal} />
          {children}
        </Contents>
      </Wrapper>
    );
  }
}

Modal.defaultProps = {
  closeModal: () => null,
};

Modal.propTypes = {
  /** Callback to close the modal */
  closeModal: PropTypes.func,
  /** Indicates if the modal is currently hidden */
  hidden: PropTypes.bool.isRequired,
  /** child components that should be in here */
  children: PropTypes.any,
};

export default Modal;
