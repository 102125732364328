/**
*
* NotFound
*
*/

import React from 'react';
import { FormattedMessage } from 'react-intl';

// local
import messages from './messages';
import Wrapper from './Wrapper';

/**
 * The NotFound component
 * @return {Function} The JSX component for a NotFound
 */
function NotFound() {
  return (
    <Wrapper>
      <div className="public-shade"></div>
      <div className="public-content">
        <header>
          <h1>404</h1>
        </header>
        <p><FormattedMessage {...messages.header} /></p>
      </div>
    </Wrapper>
  );
}

NotFound.propTypes = {
};

export default NotFound;
