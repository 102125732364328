/**
*
* SubmitButton
* Submit button
*/

import styled from 'styled-components';


const SubmitButton = styled.button`
  width: ${(props) => props.img ? '100%' : 'auto'};
  display: 'inline-block';
  color: ${(props) => props.theme.gold};
  font-size: ${(props) => props.fontSize || '1.5em'};

  text-decoration: none;
  cursor: pointer;
  z-index: 40;
  position: relative;


  &:not(:last-child) {
    margin-right: 15px;
  }

  &:hover, &:active {
    color: ${(props) => props.theme.blue};
    ${(props) => !props.underline ? `
      text-decoration: underline;
  ` : ''}
  }
  background-color: transparent;
  border: none;
  ${(props) => props.underline ? `
    border-bottom: 1px solid;
    padding:5px;
  ` : ''}
`;

export default SubmitButton;
