/**
*
* TeamMember
*
*/

import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 18px;
  overflow: auto;

  @media (min-width: 768px) {
    padding: 24px;
  };
`;

export default Wrapper;
