/**
*
* MemberModal
*
*/

import React from 'react';
import PropTypes from 'prop-types';

// components
import H3 from '../H3';
import Col3 from '../Col3';
import Close from '../Close';

// local
import Wrapper from './Wrapper';
import Contents from './Contents';
import ImageWrapper from './ImageWrapper';
import StyledNoProfile from './StyledNoProfile';

class MemberModal extends React.Component {
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.closeModal();
    }
  }

  render() {
    const {
      closeModal, real_name, hidden, image_192, bio, current_focus,
    } = this.props;

    return (
      <Wrapper hidden={hidden}>
        <Contents innerRef={this.setWrapperRef}>
          <Close onClick={closeModal} />
          <Col3 noRightMargin><ImageWrapper image={image_192} /></Col3>
          <Col3 span2>
            <H3 justify="left" selected>{real_name}</H3>
            {(bio || current_focus) ? (
              <div>
                <p>{current_focus}</p>
                <p>{bio}</p>
              </div>
            ) : (
              <StyledNoProfile><i>This user has no profile.</i></StyledNoProfile>
            )}
          </Col3>
        </Contents>
      </Wrapper>
    );
  }
}

MemberModal.defaultProps = {
  closeModal: () => null,
};

MemberModal.propTypes = {
  /** The member's slack bio */
  bio: PropTypes.string.isRequired,
  /** The member's current focus */
  current_focus: PropTypes.string.isRequired,
  /** Callback to close the modal */
  closeModal: PropTypes.func,
  /** Indicates if the modal is currently hidden */
  hidden: PropTypes.bool.isRequired,
  /** The real name of the member */
  real_name: PropTypes.string.isRequired,
  /** The image of the member to display */
  image_192: PropTypes.string.isRequired,
};

export default MemberModal;
