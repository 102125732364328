/**
*
* StyledText
* Pads text
*/

import styled from 'styled-components';


const StyledText = styled.div`
  &:not(:last-child) {
    padding-bottom: 30px;
  }

  width: ${(props) => props.width};

  font-weight: 100;
  letter-spacing: -0.22px;
  text-align: left;

  font-size: 12px;
  line-height: 16px;
  display: ${(props) => props.noMobile ? 'none' : 'block'};
  text-indent: 0;

  @media (min-width: 768px) {
    font-size: 14px;
    line-height: 20px;
    display: block;
    text-indent: ${(props) => props.noIndent ? '' : '50px'};
  }

  @media (min-width: 992px) {
    font-size: 18px;
    line-height: 24px;
    text-indent: ${(props) => props.noIndent ? '' : '50px'};
  }
`;

export default StyledText;
