/**
*
* CloseWrapper
*
*/

import styled from 'styled-components';

const Wrapper = styled.span`
  color: ${(props) => props.theme.gold};
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;

  position: absolute;
  display: block;
  top: 0;
  right: 0;
  padding: 23px; /* half of modal's padding, which is 46px */

  &:hover {
    opacity: .7;
  }
`;

export default Wrapper;
