/**
*
* AppWrapper
*
*/

import styled from 'styled-components';

const Wrapper = styled.div`
  padding-right: 45px;
  padding-left: 45px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 768px) {
    width: 663px;
  }
  @media (min-width: 992px) {
    width: 970px;
  }
  @media (min-width: 1200px) {
    width: 1055px;
  }
`;

export default Wrapper;
