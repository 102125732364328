/**
*
* Preview
*
* Preview in explore
*/

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

// components
import A from '../A';
import Col1 from '../Col1';

import H4 from '../H4';
import ImgFull from '../ImgFull';
import Modal from '../Modal';

// local
import Wrapper from './Wrapper';

/**
 * Preview in explore
 */
class Preview extends React.Component {
  state = { hidden: true };

  render() {
    const { href, header, img } = this.props;
    const { hidden } = this.state;

    let { modalMessage } = header;
    // not an array, convert to array
    if (modalMessage && !Array.isArray(modalMessage)) {
      modalMessage = [modalMessage];
    }
    const renderFormattedMessage = () => {
      if (href) {
        return <A href={href}><FormattedMessage {...header} /></A>;
      }

      const onClickHandler = (e) => {
        this.setState(
          { hidden: false }
        );
        e.preventDefault();
      };

      if (modalMessage && modalMessage.length) {
        return <A href="#" onClick={onClickHandler}><FormattedMessage {...header} /></A>;
      }

      return <FormattedMessage {...header} />;
    };

    return (
      <Wrapper>
        {href ? (
          <a href={href}>
            <ImgFull height="148px" src={img} alt={`preview-img-${header.id}`} />
          </a>
        ) : (
          <ImgFull height="148px" src={img} alt={`preview-img-${header.id}`} />
        )}
        <H4 alignCenter>
          {renderFormattedMessage()}
        </H4>
        {modalMessage && modalMessage.length && (
          <Modal
            closeModal={() => this.setState(
              { hidden: true }
            )}
            hidden={hidden}
          >
            <Col1>
              {modalMessage.map((message, i) => (
                <div>
                  {i > 0 && <br />}
                  {message}
                </div>
              ))}
            </Col1>
          </Modal>
        )}
      </Wrapper>
    );
  }
}


Preview.propTypes = {
  header: PropTypes.object,
  href: PropTypes.string,
  img: PropTypes.string,
};

export default Preview;
