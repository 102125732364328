/* eslint-disable max-len */
/*
 * About Messages
 *
 * This contains all the text for the About container.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header1: {
    defaultMessage: 'A Note from',
    id: 'app.containers.About.header1',
  },
  header2: {
    defaultMessage: 'Our Founding Member',
    id: 'app.containers.About.header2',
  },
  p1: {
    defaultMessage: `
    In the last 12 years, I’ve had the opportunity to work at not one but two startups that were rocket ship rides: Facebook & Dropbox. Those rides were exhilarating ... we dove right in with no fear of the unknown; all we had was a strong belief and each other. It resulted in products that impacted millions, a culture designed for innovation, but most of all a feeling of kinship and relationships that will last a lifetime.
`,
    id: 'app.containers.About.p1',
  },
  p2: {
    defaultMessage: `
    Leaving felt like crash landing. I missed the adrenaline of being at a startup, I missed the focus of working on a singular mission, but most of all I missed the camaraderie. So I started a learning group with folks who were in a similar position and looking for their next project. The first group was just 10 people around my dining table. There was so much happening in late 2015: large companies were doubling down on AI, cryptocurrency was gaining traction, lots of cool demos were being built in AR/VR, cost curves of genomics were coming down, and microbiomes were being widely recognized as crucial for healthcare. I had been heads down for so long and hadn’t had much time to learn about these domains. Our group picked one topic a month and we studied it together. We read research papers, invited domain experts to speak, and facilitated brainstorming sessions. We decided to get an office space in South Park, San Francisco. And inspired by Benjamin Franklin’s Junto club, the Bloomsbury group and other learning societies we came up with a manifesto, member criteria and member expectations. But most importantly we decided to become self-organizing and self-sustaining to ensure the longevity of the community.
`,
    id: 'app.containers.About.p2',
  },
  p3: {
    defaultMessage: `
    From these origins, dozens of curious and talented members have joined the Commons and made it their own. Members move on to start companies, find new jobs, pursue research, join philanthropies, or gain new domain expertise. If you are curious and interested in exploring new ideas and directions, we invite you to join South Park Commons!
`,
    id: 'app.containers.About.p3',
  },
  footer1: {
    defaultMessage: `
Ruchi Sanghvi,
`,
    id: 'app.containers.About.footer1',
  },
  footer2: {
    defaultMessage: `
First Member of
`,
    id: 'app.containers.About.footer1',
  },
  footer3: {
    defaultMessage: `
South Park Commons
`,
    id: 'app.containers.About.footer1',
  },
});
