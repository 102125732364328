/**
*
* Nav
*
*/

import React from 'react';
// import PropTypes from 'prop-types';

// components
import NavLink from '../NavLink';
import Toggler from '../Toggler';

// local
import CollapseWrapper from './CollapseWrapper';
import Li from './Li';
import Ul from './Ul';
import Modal from './Modal';
import Wrapper from './Wrapper';

/**
 * The Nav component
 * @return {Function} The JSX component for a Nav
 */
class Nav extends React.Component {
  state = { hidden: true }

  paths = [{
    path: '/story',
    title: 'Our Story',
    external: false,
  }, {
    path: '/community',
    title: 'Community',
    external: false,
  }, {
    path: '/fund',
    title: 'Fund',
    external: false,
  }, {
    path: '/events',
    title: 'Events',
    external: false,
  }, {
    path: '/faq',
    title: 'FAQ',
    external: false,
  }, {
    path: '/apply',
    title: 'Apply',
    external: false,
  }]

  toggle = () => {
    this.setState({ hidden: !this.state.hidden });
  }

  render() {
    const { hidden } = this.state;

    return (
      <Wrapper isHidden={hidden} >
        <CollapseWrapper role="navigation">
          <Toggler isHidden={hidden} open={!hidden} onClick={this.toggle} />
          <Modal isHidden={hidden}>
            <Ul isHidden={hidden}>
              {this.paths.map((path) => (
                 <Li key={path.path}><NavLink close={this.toggle} for_footer={false} {...path} /></Li>
              ))}
            </Ul>
          </Modal>
        </CollapseWrapper>
      </Wrapper>
    );
  }
}

Nav.propTypes = {
};

export default Nav;
