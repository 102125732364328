/**
 * Main index file
 */

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from 'react-apollo';

// containers
import App from './containers/App';
import LanguageProvider from './containers/LanguageProvider';

// local
import './index.css';
// import registerServiceWorker from './registerServiceWorker';
import { GRAPHQL_ENDPOINT } from './constants';
import { translationMessages } from './i18n';

// Graphql client
const client = new ApolloClient({
  link: new HttpLink({ uri: GRAPHQL_ENDPOINT }),
  cache: new InMemoryCache(),
  queryDeduplication: true,
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <LanguageProvider messages={translationMessages}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </LanguageProvider>
  </ApolloProvider>,
  document.getElementById('root')
);
// registerServiceWorker();
