/**
*
* TogglerWrapper
*
*/

import styled from 'styled-components';

const Wrapper = styled.div`
  cursor: pointer;
  border-radius: 5px;
  padding: 4px 9px;
  margin: 10px 40px 10px 0;
  width: 25px;
  float: right;
  position: relative;
  z-index: 101;

  &:hover {
    background-color: ${(props) => props.open ? 'transparent' : props.theme.text};
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

export default Wrapper;
