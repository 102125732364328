/**
*
* Input
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

// components
import FakeA from '../FakeA';
import Label from '../Label';
import Validate from '../Validate';

// local
import StyledInput from './StyledInput';
import SubmitWrapper from './SubmitWrapper';
import Wrapper from './Wrapper';

class Input extends React.Component { // eslint-disable-line
  state = { value: '' }

  onChange = (e) => {
    this.setState({ value: e.target.value });
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }
  render() {
    const {
      label, hideStar, name, required, onSubmit, submitMessage, value, ...inputProps
    } = this.props;

    const useValue = value || this.state.value;
    return (
      <Label htmlFor={name}>
        {label}
        <Wrapper>
          <StyledInput
            onChange={this.onChange}
            {...inputProps}
            name={name}
            required={required}
            value={useValue}
          />
          {!hideStar && <Validate isValid={!required || useValue.length > 0}>*</Validate>}
          {onSubmit && submitMessage && (
            <SubmitWrapper>
              <FakeA onClick={onSubmit} fontSize="14px">
                <FormattedMessage {...submitMessage} />
              </FakeA>
            </SubmitWrapper>
          )}
        </Wrapper>
      </Label>
    );
  }
}

Input.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  onSubmit: PropTypes.func,
  submitMessage: PropTypes.object,
  hideStar: PropTypes.bool,
  /** The label for the input name */
  label: PropTypes.string,
  /** The input name */
  name: PropTypes.string.isRequired,
  /** The initial value of the input */
  initialValue: PropTypes.string,
  /** Indicates if the field is required */
  required: PropTypes.bool,
};

export default Input;
