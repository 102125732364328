/**
*
* UpArrow
* An up arrow
*/

import styled from 'styled-components';


const UpArrow = styled.div`
  display: ${(props) => props.hidden ? 'block' : 'none'};
  height: 10px;
  width: 10px;
  transform: rotate(-45deg);
  cursor: pointer;
  bottom: 30px;
  left: calc(50% - 5px);
  z-index: 5;
  position: absolute;
  border-left: 1px solid blue;
  border-bottom: 1px solid blue;
`;

export default UpArrow;
