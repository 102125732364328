/**
*
* InputWrapper
*
*/

import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  margin-top: 5px;
  border-bottom: solid 1px ${(props) => props.theme.gold};
`;

export default Wrapper;
