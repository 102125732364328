/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';

// local
import { DEFAULT_LOCALE } from './constants';

export class LanguageProvider extends React.PureComponent { // eslint-disable-line
  render() {
    return (
      <IntlProvider
        key={DEFAULT_LOCALE}
        locale={DEFAULT_LOCALE}
        messages={this.props.messages[DEFAULT_LOCALE]}
      >
        {React.Children.only(this.props.children)}
      </IntlProvider>
    );
  }
}

LanguageProvider.propTypes = {
  /** The children to display */
  children: PropTypes.element.isRequired,
  /** The message object */
  messages: PropTypes.object,
};

export default LanguageProvider;
