/**
*
* Col3
* Column 1/3 page
*/

import styled from 'styled-components';


const Col3 = styled.div`
  @media (min-width: 768px) {
    display: inline-block;
    width: calc(${(props) => props.span2 ? '58%' : '33%'} - 12px);
    margin: 10px;
    margin-left: ${(props) => props.noLeftMargin ? '0' : '10px'};
    margin-right: ${(props) => props.noRightMargin ? '0' : '10px'};
    vertical-align: top;
  }
`;

export default Col3;
