/* eslint-disable max-len */
/*
 * Summer FAQ Messages
 *
 * This contains all the text for the Summer FAQ container.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    defaultMessage: 'SPC Summer Program',
    id: 'app.containers.SummerFAQ.header',
  },
});
