/**
 * Wrapper
 */

import styled from 'styled-components';

// components
import A from '../A';

const Wrapper = styled(A)`
  padding-top: 30px;
  position: relative;
  float:left;
`;

export default Wrapper;
