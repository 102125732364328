/**
 * NameWrapper
 */

import styled from 'styled-components';

// components

const NameWrapper = styled.div`
  color: #DCA55E;
  font-weight: bold;
  float: right;
  text-align: left;
  width: 80%;
  font-size: 12px;
  margin-top: 10px;

  @media (min-width: 768px) {
    font-size: 14px;
  }

  @media (min-width: 992px) {
    font-size: 18px;
  }
`;

export default NameWrapper;
