/**
 * ImgWrapper
 */

import styled from 'styled-components';

const ImgWrapper = styled.div`
  height: 160px;
`;

export default ImgWrapper;
