/**
 * StyledLocationA
 */

import styled from 'styled-components';

const StyledLocationA = styled.a`
  display: block;
  margin: 1rem auto 2rem;
  text-align: center;
  font-size: .8em;
  text-decoration: none;
  color: ${(props) => props.theme.black};
`;

export default StyledLocationA;
