/**
*
* Ul
*
*/

import styled from 'styled-components';

const Ul = styled.ul`
  float: left;
  display: ${(props) => props.isHidden ? 'none' : 'inline-block'};
  list-style-type: none;
  position: fixed;
  padding-top: 50px;

  @media (min-width: 768px) {
    -webkit-padding-start: 7px;
    overflow: none;

    position: static;
    display: inline-block;
    width: 100%;
    border-top: transparent;
    padding-right: 43px;
    padding-top: 2px;
  }
`;

export default Ul;
