/**
*
* Label
*
*/

import styled from 'styled-components';

const Label = styled.label`
  width: 100%;
  font-weight: 400;
  font-size: 0.9em;
  color: ${(props) => props.theme.label};
  display: block;
`;

export default Label;
