/* eslint-disable camelcase */
/**
*
* Member
*
*/

import React from 'react';
import PropTypes from 'prop-types';

// components
import MemberModal from '../MemberModal';

// local
import Wrapper from './Wrapper';
import InnerWrapper from './InnerWrapper';
import NameWrapper from './NameWrapper';

class Member extends React.Component {
  state = { hidden: true }

  render() {
    const { hidden } = this.state;
    const {
      // eslint-disable-next-line no-unused-vars
      bio, current_focus, first_name, real_name, image_192, modal,
    } = this.props;

    return (
      <div>
        {modal && (
          <MemberModal
            closeModal={() => this.setState({ hidden: true })}
            real_name={first_name}
            hidden={hidden}
            image_192={image_192}
            bio={bio}
            current_focus={current_focus}
          />
        )}
        <Wrapper onClick={() => this.setState({ hidden: false })}>
          <InnerWrapper>
            <img src={image_192} alt="main-member" />
          </InnerWrapper>
          <NameWrapper>
            {first_name}
          </NameWrapper>
        </Wrapper>
      </div>
    );
  }
}

Member.defaultProps = {
  modal: false,
};

Member.propTypes = {
  /** The member's slack bio */
  bio: PropTypes.string,
  /** The member's current focus */
  current_focus: PropTypes.string,
  first_name: PropTypes.string,
  /** Whether to include the modal for the member */
  modal: PropTypes.bool,
  /** The display image for the member */
  image_192: PropTypes.string.isRequired,
  /** The display name for the member */
  real_name: PropTypes.string.isRequired,
};

export default Member;
