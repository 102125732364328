/**
 * A link to a certain page, an anchor tag
 */

import styled from 'styled-components';

const A = styled.span`
  width: ${(props) => props.img ? '100%' : 'auto'};
  display: 'inline-block';
  color: ${(props) => props.theme.gold};
  font-size: ${(props) => props.fontSize || '1.5em'};
  ${(props) => props.underline ? `
    border-bottom: 1px solid;
    padding:5px;
  ` : ''}
  text-decoration: none;
  cursor: pointer;
  z-index: 40;
  position: relative;



  &:hover, &:active {
    color: ${(props) => props.theme.blue};
    ${(props) => !props.underline ? `
      text-decoration: underline;
  ` : ''}
  }
`;

export default A;
