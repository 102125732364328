/**
*
* StyledH2
* Styled h2
*/

import styled from 'styled-components';

// components
import H2 from '../H2';

const StyledH2 = styled(H2)`
  color: ${(props) => props.theme.black};
  ${(props) => props.marginTop ? 'margin-top: 10px;' : ''}
  ${(props) => props.marginBottom ? 'margin-bottom: 20px;' : ''}
`;

export default StyledH2;
