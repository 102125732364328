/**
*
* Icon
*
*/

import React from 'react';
import PropTypes from 'prop-types';

// local

/**
 * The Icon component
 * @return {Function} The JSX component for a Icon
 */
function Icon({ type }) {
  return (
    <i className={type} />
  );
}

Icon.propTypes = {
  /** The icon classname */
  type: PropTypes.string.isRequired,
};

export default Icon;
