/**
*
* App
*
*/

import React from 'react';
// import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router';
import { ThemeProvider } from 'styled-components';

// global
import { THEME } from '../../constants';

// components
import Header from '../../components/Header';
import Footer from '../../components/Footer';

// containers
import Home from '../Home';
import About from '../About';
import Community from '../Community';
import Events from '../Events';
import Fund from '../Fund';
import Blog from '../Blog';
import Apply from '../Apply';
import NotFound from '../NotFound';
import FAQ from '../FAQ';
import SummerFAQ from '../SummerFAQ';

// local
import Wrapper from './Wrapper';
import PageWrapper from './PageWrapper';
import MainWrapper from './MainWrapper';


class App extends React.Component { // eslint-disable-line
  render() {
    const isHome = window.location.pathname === '/';
    const isFund = window.location.pathname === '/fund';
    return (
      <ThemeProvider theme={THEME}>
        <MainWrapper isFund={isFund}>
          <Header />
          <Wrapper>
            <PageWrapper isHome={isHome}>
              <Switch>
                <Route component={Home} path="/" exact />
                <Route component={About} path="/story" exact />
                {/* 2019-10-07: Changed URL from /about to /story, so keep redirecting there  */}
                <Route path="/about" exact render={() => <Redirect to="/story" />} />
                <Route component={Community} path="/community" exact />
                <Route component={Events} path="/events" exact />
                <Route component={Fund} path="/fund" exact />
                <Route component={Blog} path="/blog" exact />
                <Route component={Apply} path="/apply" exact />
                <Route component={FAQ} path="/faq" exact />
                <Route component={SummerFAQ} path="/summer" exact />
                <Route component={NotFound} path="" />
              </Switch>
            </PageWrapper>
            <Footer />
          </Wrapper>
        </MainWrapper>
      </ThemeProvider>
    );
  }
}

App.propTypes = {
};

export default App;
