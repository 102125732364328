/**
 *
 * About
 *
 * About info
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';

// components
import BigText from '../../components/BigText';
import Img from '../../components/Img';
import PageFooter from '../../components/PageFooter';
import StyledText from '../../components/StyledText';
import InnerDiv from '../../components/InnerDiv';
import OuterDiv from '../../components/OuterDiv';

// containers
import Press from '../Press';

// local
import ArrowWrapper from './ArrowWrapper';
import imgDesktop from './climb-desktop.png';
import arrow from './arrow.png';
import shapes from './shapes.png';
import shapesMobile from './shapes-mobile.png';
import ImgWrapper from './ImgWrapper';
import messages from './messages';
import ShapesWrapper from './ShapesWrapper';
import TextWrapper from './TextWrapper';
import Wrapper from './Wrapper';
import TextContainer from './TextContainer';
import AboutContainer from './AboutContainer';

/**
 * The About component
 * @return {Function} The JSX component for a About
 */
export function About() {
  return (
    <Wrapper>
      <Helmet>
          <title>Our Story | South Park Commons</title>
          <meta name="description" content="Read the founder's, Ruchi Sanghvi, letter on why she started South Park Commons." />
          <meta property="og:title" content="Our Story | South Park Commons" />
          <meta property="og:description" content="Read the founder's, Ruchi Sanghvi, letter on why she started South Park Commons." />
          <meta name="twitter:title" content="Our Story | South Park Commons" />
          <meta name="twitter:description" content="Read the founder's, Ruchi Sanghvi, letter on why she started South Park Commons." />
      </Helmet>
      <BigText>
        <FormattedMessage {...messages.header1} /> <br />
        <FormattedMessage {...messages.header2} />
      </BigText>
      <ImgWrapper noMobile>
        <Img src={imgDesktop} alt="climbing" />
      </ImgWrapper>
      <AboutContainer>
        <TextContainer>
          <TextWrapper>
            <StyledText>
              <FormattedMessage {...messages.p1} />
            </StyledText>
            <StyledText>
              <FormattedMessage {...messages.p2} />
            </StyledText>
            <StyledText>
              <FormattedMessage {...messages.p3} />
            </StyledText>
            <StyledText noIndent>
              <div><FormattedMessage {...messages.footer1} /></div>
              <div><FormattedMessage {...messages.footer2} /></div>
              <div><FormattedMessage {...messages.footer3} /></div>
            </StyledText>
          </TextWrapper>
          <div style={{ height: '55px' }}></div>
        </TextContainer>
        <ArrowWrapper><Img height="50px" src={arrow} alt="arrow" /></ArrowWrapper>
        <ShapesWrapper noMobile><Img height="92px" src={shapes} alt="shapes" /></ShapesWrapper>
        <ShapesWrapper noDesktop><Img src={shapesMobile} alt="shapes" /></ShapesWrapper>
        <OuterDiv top noDesktop>
          <div style={{ height: '10px' }}></div>
          <InnerDiv padding="false" right />
          <InnerDiv />
        </OuterDiv>
        <OuterDiv noMobile>
          <InnerDiv top padding="false" right />
          <InnerDiv padding="false" />
          <div style={{ height: '10px' }}></div>
        </OuterDiv>
        <Press />
        <PageFooter noTop noCenterLine noDot noMobile />
      </AboutContainer>
    </Wrapper>
  );
}


About.propTypes = {
};

export default About;
