/**
*
* Company
*
* Displays a company
*/

import React from 'react';
import PropTypes from 'prop-types';

// components
import A from '../A';

// local
import StyledImg from './StyledImg';
import Wrapper from './Wrapper';
import InnerWrapper from './InnerWrapper';
import NameWrapper from './NameWrapper';
import Blank from './blank.png';

class Company extends React.Component { // eslint-disable-line
  render() {
    const {
      Logo, Name, Website,
    } = this.props;
    return (
      <div>
        <Wrapper>
          <A href={Website} target="_blank">
            <InnerWrapper>
              <StyledImg src={Logo || Blank} alt="main-company-logo" />
            </InnerWrapper>
            <NameWrapper>
              {Name}
            </NameWrapper>
          </A>
        </Wrapper>
      </div>
    );
  }
}

Company.defaultProps = {
};

Company.propTypes = {
  /** The logo of the company */
  Logo: PropTypes.string.isRequired,
  /** The name of the company */
  Name: PropTypes.string,
  /** The company website */
  Website: PropTypes.string,
};

export default Company;
