/**
*
* H3
*
*/

import styled from 'styled-components';

const H3 = styled.h3`
  text-align: ${(props) => props.justify || 'center'};
  color: ${(props) => props.selected ? props.theme.blue : props.theme.gold};
  font-weight: 500;
  line-height: 1.1;
`;

export default H3;
