/**
*
* UpArrow
* An up arrow
*/

import styled from 'styled-components';


const UpArrow = styled.div`
  height: 10px;
  width: 10px;
  transform: rotate(-45deg);
  border-left:1px solid blue;
  border-bottom:1px solid blue;

  cursor: pointer;

  display: ${(props) => props.isCollapsed ? 'block' : 'none'};

  bottom: 30px;
  left:calc(50% - 5px);
  z-index: 5;
  position: absolute;
`;

export default UpArrow;
