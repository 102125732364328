/**
*
* MemberWrapper
*
*/

import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 18px;
  cursor: pointer;

  @media (min-width: 768px) {
    padding: 24px;
  };

  &:hover * {
    color: ${(props) => props.theme.blue}
  }

`;

export default Wrapper;
