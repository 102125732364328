/* eslint-disable max-len */
/*
 * Home Messages
 *
 * This contains all the text for the Home container.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  environment: {
    defaultMessage: 'We’ve created this environment to help you take risks and launch your next endeavor. You don’t come here to play it safe—we’re here to dive off the deep end together.',
    id: 'app.containers.Home.environment',
  },
  header: {
    defaultMessage: 'South Park Commons',
    id: 'app.containers.Home.header',
  },
  shots: {
    defaultMessage: 'You only get a few shots at building your life’s work—the kind of work that makes a difference, that can’t happen without you.',
    id: 'app.containers.Home.shots',
  },
  title: {
    defaultMessage: ' is a community that helps you get from -1 to 0. We’re a home for technologists, builders, and domain-experts figuring out what’s next — a place to turn the chaos of possibility into the clarity of conviction.',
    id: 'app.containers.Home.title',
  },
  together: {
    defaultMessage: 'South Park Commons brings together talented people to share ideas, explore directions, and realize the opportunities that’ll get you there.',
    id: 'app.containers.Home.together',
  },
  exploreHeader: {
    defaultMessage: 'Explore',
    id: 'app.containers.Home.exploreHeader',
  },
  exploreDescription: {
    defaultMessage: 'When you’re figuring out what to do next, at SPC it’s okay to not have all the answers.',
    id: 'app.containers.Home.exploreDescription',
  },
  learnHeader: {
    defaultMessage: 'Learn',
    id: 'app.containers.Home.learnHeader',
  },
  learnDescription: {
    defaultMessage: 'Achieve mastery with a community of curious and ambitious technologists.',
    id: 'app.containers.Home.learnDescription',
  },
  launchHeader: {
    defaultMessage: 'Launch',
    id: 'app.containers.Home.launchHeader',
  },
  launchDescription: {
    defaultMessage: 'Once you’ve figured out what you want to do, our community will help with intros, hiring, testing, and fundraising.',
    id: 'app.containers.Home.launchDescription',
  },
});
