/**
*
* FAQ
*
* Displays a single Q&A
*/

import React from 'react';
import PropTypes from 'prop-types';
import {Panel} from 'rc-collapse';

import {
  CollapseHeadline,
  StyledCollapse,
  StyledAnswer
} from './CollapsibleAnswerComponents'

class SingleFAQ extends React.Component { // eslint-disable-line
  render() {
      
    const {Question, Answer} = this.props;
      
    return (
      <StyledCollapse destroyInactivePanel={true}>
        <Panel accordion={true} header={<CollapseHeadline title="Click to expand">{Question}</CollapseHeadline>}>
          <StyledAnswer>{Answer}</StyledAnswer>
        </Panel>
      </StyledCollapse>
    );
  }
}

SingleFAQ.defaultProps = {
};

SingleFAQ.propTypes = {
  Question: PropTypes.string,
  Answer: PropTypes.string,
  Grouping: PropTypes.string,
  Rank: PropTypes.number
};

export default SingleFAQ;
