/**
 * NameWrapper
 */

import styled from 'styled-components';

// components
import H4 from '../H4';

const NameWrapper = styled(H4)`
  text-align: center;
  width: 96px;
  font-size: 12px;

  @media (min-width: 768px) {
    font-size: 14px;
  }

  @media (min-width: 992px) {
    font-size: 18px;
  }
`;

export default NameWrapper;
