/**
*
* StyledH3
* styled h3
*/

import styled from 'styled-components';

// components
import H3 from '../H3';

const StyledH3 = styled(H3)`
  margin: 0;
  color: ${(props) => props.theme.black};
  font-size: 18px;
  font-weight: 100;
  letter-spacing: -0.22px;
  text-align: left;
  line-height: 24px;
`;

export default StyledH3;
