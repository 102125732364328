/**
*
* Logo
*
*/

import React from 'react';
// import PropTypes from 'prop-types';

// local
import StyledImgLogo from './StyledImgLogo';
import Wrapper from './Wrapper';
import logo from './SPCLogo.svg';

/**
 * The Logo component
 * @return {Function} The JSX component for a Logo
 */
function Logo() {
  return (
    <Wrapper href="/">
      <StyledImgLogo src={logo} />
    </Wrapper>
  );
}

Logo.propTypes = {
};

export default Logo;
