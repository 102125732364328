/**
*
* Event
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';

// components
import A from '../A';

// local
import StyledH4 from './StyledH4';
import StyledImg from './StyledImg';
import ImgWrapper from './ImgWrapper';
import Wrapper from './Wrapper';

/**
 * The Event component
 * @return {Function} The JSX component for a Event
 */
function Event({
  Date, showDate, Name, Speaker, src, Link,
}) {
  const content = (
    <div>
      <ImgWrapper>
        <StyledImg alt="events-thumbnail" src={src} />
      </ImgWrapper>
      <StyledH4>
        {showDate && (
          <span>
            <Moment format="MM.DD.YY">{Date}</Moment>&nbsp;
          </span>
        )}
        {Name}
        <div>
          {Speaker}
        </div>
      </StyledH4>
    </div>
  );
  return (
    <Wrapper hover={Link}>
      {Link ?
        <A href={Link} target="_blank">{content}</A> :
        content
      }
    </Wrapper>
  );
}

Event.propTypes = {
  /** The event image */
  src: PropTypes.string.isRequired,
  Date: PropTypes.string,
  Link: PropTypes.string,
  /** The event title */
  Name: PropTypes.string.isRequired,
  Speaker: PropTypes.string,
  showDate: PropTypes.bool,
};

export default Event;
