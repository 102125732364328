/**
 *
 * Apply
 *
 * Apply to spc
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';

// components
import BigText from '../../components/BigText';
import H2 from '../../components/H2';
import Intro from '../../components/Intro';
import OuterDiv from '../../components/OuterDiv';
import InnerDiv from '../../components/InnerDiv';
import UpArrow from '../../components/UpArrow';
import ApplyLink from '../../components/ApplyLink';
import FooterWrapper from '../../components/PageFooter/Wrapper';

// containers
import Newsletter from '../Newsletter';

// local
import messages from './messages';
import Wrapper from './Wrapper';

class Apply extends React.Component {
  render() {
    return (
      <Wrapper>
        <Helmet>
          <title>Apply | South Park Commons</title>
          <meta name="description" content="Do you want to validate an idea, develop domain expertise, or catalyze your next company? South Park Commons welcomes exceptional technologists and entrepreneurs." />
          <meta property="og:title" content="Apply | South Park Commons" />
          <meta property="og:description" content="Do you want to validate an idea, develop domain expertise, or catalyze your next company? South Park Commons welcomes exceptional technologists and entrepreneurs." />
          <meta name="twitter:title" content="Apply | South Park Commons" />
          <meta name="twitter:description" content="Do you want to validate an idea, develop domain expertise, or catalyze your next company? South Park Commons welcomes exceptional technologists and entrepreneurs." />
        </Helmet>
        <Intro>
          <H2><FormattedMessage {...messages.header} /></H2>
          <BigText>
            <FormattedMessage {...messages.info} />
          </BigText>
        </Intro>
        <ApplyLink become />
        <OuterDiv margin>
          <UpArrow />
          <InnerDiv height="120px" right />
          <InnerDiv />
        </OuterDiv>
        <iframe title="Apply | South Park Commons" class="airtable-embed" src="https://airtable.com/embed/shrWCPY3XTu3A3OVm?backgroundColor=cyan" frameborder="0" onmousewheel="" width="100%" height="2130" style={{background: "transparent", border: "1px solid #ccc"}}></iframe>
        <FooterWrapper>
          <Newsletter />
        </FooterWrapper>
      </Wrapper>
    );
  }
}

export default Apply;
