/**
*
* H2
*
*/

import styled from 'styled-components';

const H2 = styled.h2`
  text-align: center;
  color: ${(props) => props.selected ? props.theme.blue : props.theme.gold};
  font-size: 36px;
  font-weight: 500;
  letter-spacing: 0;
  text-align: center;
  line-height: 46px;
`;

export default H2;
