/**
*
* ModalWrapper
*
*/

import styled from 'styled-components';

const Wrapper = styled.div`
  z-index: 100;
  position: fixed; /* Stay in place */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(255,255,255, .9); /* Black w/ opacity */
`;

export default Wrapper;
