/**
*
* InnerDiv
* Inner div for home page
*/

import styled from 'styled-components';


const InnerDiv = styled.div`
  display: ${(props) => props.noMobile ? 'none' : 'inline-block'};
  padding: 19px 16px;

  @media (min-width: 768px) {
    display: ${(props) => props.noDesktop ? 'none' : 'inline-block'};
    padding: ${(props) => props.padding === 'false' ? 'auto' : '35px 31px'};
  };

  @media (min-width: 992px) {
    padding: ${(props) => props.padding === 'false' ? 'auto' : '58px 34px'};
  };

  box-sizing: border-box;
  text-align: left;
  width: ${(props) => props.full ? '100%' : '50%'};
  height: ${(props) => props.height || '100%'};
  vertical-align: ${(props) => props.bottom ? 'bottom' : 'top'};
  position:relative;

  border-top: ${(props) => props.top ? '1px solid blue' : 'none'};
  border-bottom: ${(props) => props.bottom ? '1px solid blue' : 'none'};
  border-left: ${(props) => props.left ? '1px solid blue' : 'none'};
  border-right: ${(props) => props.right ? '1px solid blue' : 'none'};
`;

export default InnerDiv;
