/**
*
* StyledH4
* Styled h4
*/

import styled from 'styled-components';

// components
import H4 from '../H4';

const StyledH4 = styled(H4)`
  font-size: 18px;
  text-align: left;
  line-height: 24px;
  font-weight: 200;
`;

export default StyledH4;
