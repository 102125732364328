/**
*
* Ul
*
*/

import styled from 'styled-components';

const Ul = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  justify-content: center;

  padding-bottom: 30px;
  padding-top: 0px;
  padding-left: 20px;
  padding-right: 20px;

  margin: 0;

  @media (min-width: 768px) {
    -webkit-padding-start: 7px;
    overflow: none;

    display: inline-block;
    border-top: transparent;
    padding-right: 43px;
    padding-left: 43px;
    padding-top: 2px;
  }
`;

export default Ul;
