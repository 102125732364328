/**
*
* IconLink
*
*/

import React from 'react';
import PropTypes from 'prop-types';

// components
import A from '../A';
import Icon from '../Icon';

// local

/**
 * The IconLink component
 * @return {Function} The JSX component for a IconLink
 */
function IconLink({ href, type }) {
  return (
    <A target="new" href={href}>
      <Icon type={type} />
    </A>
  );
}

IconLink.propTypes = {
  /** The link the icon refers to */
  href: PropTypes.string.isRequired,
  /** The type of icon */
  type: PropTypes.string.isRequired,
};

export default IconLink;
