/**
 *
 * Blog
 *
 * The spc blog
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';


// components

// containers

// utils

// local
import messages from './messages';

/**
 * The Blog component
 * @return {Function} The JSX component for a Blog
 */
export function Blog() {
  return (
    <div>
      <FormattedMessage {...messages.header} />
    </div>
  );
}


Blog.propTypes = {
};

export default Blog;
