/**
*
* A
*
*/

import styled from 'styled-components';

const A = styled.a`
  width: ${(props) => props.img ? '100%' : 'auto'};
  display: 'inline-block';
  color: ${(props) => props.theme.gold};
  font-size:1.5em;
  ${(props) => props.underline ? `
    border-bottom: 1px solid;
    padding:5px;
  ` : ''}
  text-decoration: none;
  cursor: pointer;
  z-index: 10;
  position: relative;

  &:not(:last-child) {
    margin-right: 15px;
  }

  &:hover, &:active {
    color: ${(props) => props.theme.blue};
    ${(props) => !props.underline ? 'text-decoration: underline;' : ''}
  }
`;

export default A;
