/**
*
* FAQs
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Helmet } from 'react-helmet';

// components
import ApplyLink from '../../components/ApplyLink';
import Section from '../../components/Section';
import SingleFAQ from '../../components/FAQ';
import LoadingIndicator from '../../components/LoadingIndicator';
import ErrorMessage from '../../components/ErrorMessage';

/**
 * The FAQs content fetcher/renderer
 */

class SummerFAQs extends React.Component {

  render() {
    const { data: { loading, summerfaqs, error } } = this.props;
    let content;

    // Loading testimonials from backend
    if (loading) content = <LoadingIndicator />;
    // An error occurred while loading testimonials
    else if (error) content = <ErrorMessage >{error.message}</ErrorMessage>;
    // FAQs were loaded
    else if (Object.values(summerfaqs).length > 0) {
        
      var groupedFAQ = {}
        
      // Prepare list of sections for FAQ
      var groupings = [...new Set(summerfaqs.map(faq => faq.Grouping))].filter(function (label) {
         return label != null;
      });
        
      // Make grouped dict from array of FAQ
      groupings.map((grouping) =>
          groupedFAQ[grouping] = Object.values(summerfaqs).filter(function(faq) {
            return faq.Grouping === grouping
         }).sort((a, b) => (a.Rank > b.Rank) ? 1 : -1)
      )
        
      // Sort groups using "lowest" rank
      groupings = groupings.sort((a, b) =>
         (groupedFAQ[a][0].Rank > groupedFAQ[b][0].Rank) ? 1 : -1
      )
      
      content = (
        <Section id="summerfaqs">
            {groupings.map((grouping) =>
              <div>
                 <h2 style={{color: "#DCA55E"}}> {grouping} </h2>
                  <div style={{ height: '20px' }} />
                  {groupedFAQ[grouping].map((faq) => (
                   <div>
                     <SingleFAQ key={faq.Question} {...faq} />
                   </div>
                 ))}
              </div>
            )}
          <ApplyLink />
        </Section>
      );
    }

    return (
      <div>
        <Helmet>
            <title>Summer Program | South Park Commons</title>
            <meta name="description" content="South Park Commons Summer Program." />
            <meta property="og:title" content="Summer Program | South Park Commons" />
            <meta property="og:description" content="South Park Commons Summer Program." />
            <meta name="twitter:title" content="Summer Program | South Park Commons" />
            <meta name="twitter:description" content="South Park Commons Summer Program." />
        </Helmet>
        {content}
      </div>
    );
  }
}

SummerFAQs.propTypes = {
  /** The graphql request data for faqs */
  data: PropTypes.object.isRequired,
};

export default graphql(gql`
  query SummerFAQsQuery {
    summerfaqs {
      Question
      Answer
      Grouping
      Rank
    }
  }
`)(SummerFAQs);
