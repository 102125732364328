/* eslint-disable max-len */
/*
 * Apply Messages
 *
 * This contains all the text for the Apply container.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    defaultMessage: 'Apply to join us',
    id: 'app.containers.Apply.header',
  },
  info: {
    defaultMessage: 'Do you want to validate an idea, develop domain expertise, or catalyze your next company? South Park Commons welcomes exceptional technologists and entrepreneurs.',
    id: 'app.containers.Apply.info',
  },
});
