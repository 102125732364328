/**
*
* Events
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { FormattedMessage } from 'react-intl';
import { sampleSize } from 'lodash';
import { Helmet } from 'react-helmet';

// components
import EventsPreview from '../../components/EventsPreview';
import BigText from '../../components/BigText';
import H2 from '../../components/H2';
import Intro from '../../components/Intro';
import InnerDiv from '../../components/InnerDiv';
import PageFooter from '../../components/PageFooter';
import OuterDiv from '../../components/OuterDiv';
import UpArrow from '../../components/UpArrow';

// local
import messages from './messages';
import Wrapper from './Wrapper';

import past1 from './past1.png';
import past2 from './past2.png';
import past3 from './past3.png';

import upcoming1 from './upcoming1.png';
import upcoming2 from './upcoming2.png';
import upcoming3 from './upcoming3.png';

const PAST_IMGS = [past1, past2, past3];
const UPCOMING_IMGS = [upcoming1, upcoming2, upcoming3];

const getEvents = ({
  events, fillerImages, filterEvents, select = 3,
}) => {
  let ind = 0;
  const filteredEvents = events ? events.filter(filterEvents) : [];
  return sampleSize(filteredEvents, select)
    .map((event) => event.Attachments && event.Attachments.length && event.Attachments[0].url ?
      Object.assign({}, event, { src: event.Attachments[0].url }) :
      Object.assign({}, event, { src: fillerImages[ind++] }) // eslint-disable-line
    );
};

/**
 * The Events component
 * @return {Function} The JSX component for a Events
 */
function Events({ data: { loading, events, error } }) {
  const pastEvents = getEvents({
    events,
    fillerImages: PAST_IMGS,
    filterEvents: (event) => event.Link && event.Past,
    select: 6,
  });

  const futureEvents = getEvents({
    events,
    fillerImages: UPCOMING_IMGS,
    filterEvents: (event) => !event.Past,
  });

  const allProps = {
    loading,
    error,
  };

  return (
    <Wrapper>
      <Helmet>
          <title>Events | South Park Commons</title>
          <meta name="description" content="We host lectures, brainstorming events, and workshops on topics members are exploring. Since our founding, we’ve discussed AI, cryptocurrency, astrophysics, and more." />
          <meta property="og:title" content="Events | South Park Commons" />
          <meta property="og:description" content="We host lectures, brainstorming events, and workshops on topics members are exploring. Since our founding, we’ve discussed AI, cryptocurrency, astrophysics, and more." />
          <meta name="twitter:title" content="Events | South Park Commons" />
          <meta name="twitter:description" content="We host lectures, brainstorming events, and workshops on topics members are exploring. Since our founding, we’ve discussed AI, cryptocurrency, astrophysics, and more." />
      </Helmet>
      <Intro>
        <H2><FormattedMessage {...messages.header} /></H2>
        <BigText>
          <FormattedMessage {...messages.title} />
        </BigText>
      </Intro>
      <div style={{ height: '70px' }} />
      <OuterDiv margin>
        <UpArrow />
        <InnerDiv height="120px" right />
        <InnerDiv />
      </OuterDiv>
      {
        futureEvents.length > 0
          ?          (
            <div>
              <EventsPreview header={messages.upcoming} events={futureEvents} {...allProps} showDate />
              {
                pastEvents.length > 0
                  ? <InnerDiv height="120px" right />
                  : null
              }
            </div>
          )
          : null
      }

      {
        pastEvents.length > 0
          ?            <EventsPreview header={messages.past} events={pastEvents} {...allProps} />
          : null
      }
      <PageFooter noTop noApply />
    </Wrapper>
  );
}

/**
 *     <Section id="events">
      <H2>Events</H2>
      <p>
        People at the Commons host lectures, brainstorming events, and workshops on topics that they’re exploring. For example:
      </p>
      <br />
      {content}
      <p>
        To see more events from South Park Commons, including videos, check out our&nbsp;
        <VimeoLink target="new" href="https://vimeo.com/southparkcommons">
          vimeo feed:&nbsp;&nbsp;<i className="fa fa-vimeo social-logo" />
        </VimeoLink>
      </p>
    </Section>
 */

Events.propTypes = {
  /** The list of events to display */
  data: PropTypes.object.isRequired,
};


export default graphql(gql`
  query EventsQuery {
    events {
      Date
      Name
      Speaker
      Link
      Attachments {
        url
      }
      Show
      Past
    }
  }
`)(Events);
