/**
*
* Modal
*
*/

import styled from 'styled-components';

const Modal = styled.div`
  @media (max-width: 768px) {
    float: left;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0;
    z-index: 40;
    list-style-type: none;
    background-color: ${(props) => props.isHidden ? 'transparent' : props.theme.blue};
    display: ${(props) => props.isHidden ? 'none' : 'block'};
  }
`;

export default Modal;
