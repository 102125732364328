/**
*
* StyledImgLogo
*
*/

import styled from 'styled-components';

const StyledImgLogo = styled.img`
  display: block;
  height: 40px;
  padding: 10px;
  padding-left: 60px;
`;

export default StyledImgLogo;
