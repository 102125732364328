/**
*
* StyledImg
* Styled img
*/

import styled from 'styled-components';

// components
import Img from '../Img';

const StyledImg = styled(Img)`
  position:absolute;
  top:0;
  bottom:0;
  margin:auto;
  cursor: pointer;
  transform: scale(0.75, 0.75);
`;

export default StyledImg;
