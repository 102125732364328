/* eslint-disable max-len */
/*
 * Footer Messages
 *
 * This contains all the text for the Footer component.
 */
import { defineMessages } from 'react-intl';

const currentYear = (new Date()).getFullYear()

export default defineMessages({
  address: {
    defaultMessage: '112 S Park St, San Francisco, CA 94107',
    id: 'app.containers.Footer.address',
  },
  copyright: {
    defaultMessage: `{copyright} ${currentYear} All rights reserved.`,
    id: 'app.containers.Footer.copyright',
  },
  header: {
    defaultMessage: 'South Park Commons',
    id: 'app.components.Footer.header',
  },
});
