/**
*
* PageFooter
*
* Footer for most pages
*/

import React from 'react';
import PropTypes from 'prop-types';

// components
import Wrapper from './Wrapper';
import ApplyLink from '../ApplyLink';
import Dot from '../Dot';
import InnerDiv from '../InnerDiv';
import Line from '../Line';

// containers
import Newsletter from '../../containers/Newsletter';

// local

/**
 * Footer for most pages
 * @return {Function} The JSX component for a PageFooter
 */
function PageFooter({
  noApply, noTop, moveTop, onSubmit, height, noCenterLine, noDot,
}) {
  return (
    <Wrapper moveTop={moveTop}>
      {!noTop && <Line padding="15px 0" />}
      {!noCenterLine && <InnerDiv height={height || '120px'} right />}
      {!noDot && <Dot />}
      {!noApply && <ApplyLink onSubmit={onSubmit} />}
      <Newsletter />
    </Wrapper>
  );
}


PageFooter.propTypes = {
  noApply: PropTypes.bool,
  noTop: PropTypes.bool,
  moveTop: PropTypes.string,
  onSubmit: PropTypes.func,
  height: PropTypes.string,
  noCenterLine: PropTypes.bool,
  noDot: PropTypes.bool,
};

export default PageFooter;
