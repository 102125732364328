/**
*
* A
*
*/

import styled from 'styled-components';

const ApplyLink = styled.div`
  letter-spacing: -0.22px;
  text-align: center;
  /* Apply to Be a Member: */
  font-size: 12px;
  letter-spacing: -0.05px;
  line-height: 16px;
  padding-top: 60px;
  padding-bottom: 85px;
`;

export default ApplyLink;
