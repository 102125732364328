/**
*
* Toggler
*
*/

import React from 'react';
import PropTypes from 'prop-types';

// local
import Wrapper from './Wrapper';
import Bar from './Bar';

/**
 * The Toggler component
 * @return {Function} The JSX component for a Toggler
 */
function Toggler({ onClick, open }) {
  return (
    <Wrapper onClick={onClick} open={open}>
      <Bar />
      <Bar />
      <Bar />
    </Wrapper>
  );
}

Toggler.propTypes = {
  /** Callback to occur on toggle click */
  onClick: PropTypes.func,
  /** Indicates if the toggle is open */
  open: PropTypes.bool,
};

export default Toggler;
