/**
*
* Wrapper
*
*/

import styled from 'styled-components';

const Wrapper = styled.div`
  margin-top: 25px;
`;

export default Wrapper;
