/* eslint-disable max-len */
/*
 * ApplyLink Messages
 *
 * This contains all the text for the ApplyLink component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    defaultMessage: 'Apply to Be a Member',
    id: 'app.components.ApplyLink.header',
  },
  become: {
    defaultMessage: 'Become a Member',
    id: 'app.components.ApplyLink.become',
  },
  submit: {
    defaultMessage: 'Submit',
    id: 'app.components.ApplyLink.submit',
  },
});
