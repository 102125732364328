/**
*
* AboutContainer
* Wraps tex
*/

import styled from 'styled-components';


const AboutContainer = styled.div`

    position: relative;

    @media (min-width: 768px) {
        top: -148px;
    }

    @media (min-width: 992px) {
        
        top: -200px;
    }
`;

export default AboutContainer;
