/**
 * Wrapper
 */

import styled from 'styled-components';

const Wrapper = styled.div`
  line-height: 60px;
`;

export default Wrapper;
