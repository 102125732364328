/**
*
* NavLink
*
*/

import React from 'react';
import PropTypes from 'prop-types';

// local
import Wrapper from './Wrapper';
import LinkWrapper from './LinkWrapper';


/**
 * The NavLink component
 * @return {Function} The JSX component for a NavLink
 */
function NavLink({
  close, external, path, title, for_footer, ...linkProps
}) {
  const splitPath = path.split('#');

  // Determine if the link is on the same page or not
  const isLocal = splitPath[0] === window.location.pathname && splitPath.length > 1;
  const to = isLocal ? splitPath[1] : path;
  const selected = to === window.location.pathname;

  return (
    external ?
        <Wrapper
                onClick={close}
                href={to}
                animate={{ duration: 300 }}
                selected={selected}
                for_footer={for_footer}
                {...linkProps} >
           {title}
        </Wrapper>
    :
      <LinkWrapper
                onClick={close}
                to={to}
                selected={selected}
                for_footer={for_footer}>
        {title}
     </LinkWrapper>
  );
}

NavLink.propTypes = {
  /** Callback to close the dropdown */
  close: PropTypes.func,
  /** The location to which the link refers */
  path: PropTypes.string.isRequired,
  /** The text to display for the link */
  title: PropTypes.string.isRequired,
};

export default NavLink;
