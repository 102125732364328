/*
 *
 * LanguageProvider constants
 *
 */

/**
 * The default locale
 *
 * @constant
 * @type {String}
 * @default 'en'
 */
export const DEFAULT_LOCALE = 'en';
