/**
*
* A
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

// components
import A from '../A';

// local
import SubmitButton from './SubmitButton';
import Wrapper from './Wrapper';
import messages from './messages';

/**
 * Apply link
 * @return {Object} The JSX for the apply link
 */
function ApplyLink({ become, onSubmit }) {
  return (
    <Wrapper>
      {become ? (
        <A href="/apply#apply" underline>
          <FormattedMessage {...messages.become} />
        </A>
      ) : (
        <span>
          {onSubmit ? (
            <SubmitButton type="submit" onClick={onSubmit} underline>
              <FormattedMessage {...messages.submit} />
            </SubmitButton>
          ) : (
            <A href="/apply" underline>
              <FormattedMessage {...messages.header} />
            </A>
          )}
        </span>
      )}
    </Wrapper>
  );
}

ApplyLink.propTypes = {
  become: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default ApplyLink;
