/**
 * InnerWrapper
 */

import styled from 'styled-components';

const InnerWrapper = styled.div`
  width: 96px;
  height: 96px;

  border-radius: 54px;
  overflow: hidden;

  img {
    width: 100%;
  }

  display: block;
  margin: 0 auto;
`;

export default InnerWrapper;
