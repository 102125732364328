/**
*
* ImgFull
* An img
*/

import styled from 'styled-components';


const ImgFull = styled.img`
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  display: ${(props) => props.noMobile ? 'none' : 'block'};

  @media only screen and (min-width: 768px) {
    display: block;
  })
`;

export default ImgFull;
