/**
*
* Wrapper
*
*/

import styled from 'styled-components';

const Wrapper = styled.div`
  &:hover * {
    color: ${(props) => props.theme.blue}
  }
`;

export default Wrapper;
