/**
 * PressCover
 */

import styled from 'styled-components';

const PressCover = styled.div`
  a {
    font-size: 12px;
    line-height: 24px;

    &:hover, &:active {
      color: ${(props) => props.theme.blue};
      text-decoration: none;
    }
  }
  img {
    margin-right: 15px;
    height: 20px;
    width: 20px;
    margin-bottom: -7px;
  }
  a span {
    text-decoration: none;
    border-bottom: 1px solid;
    padding: 5px 0 5px 0;
  }

  @media (min-width: 768px) {
    a span {
      font-size: 14px;
      line-height: 20px;
    }
  }

  @media (min-width: 992px) {
    a span {
      font-size: 18px;
      line-height: 30px;
    }
  }
`;

export default PressCover;
