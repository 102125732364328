/**
*
* TestimonialWrapper
*
*/

import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;

  padding: 15px 0;

  @media (min-width: 768px) {
    padding: 30px 90px;
  }
`;

export default Wrapper;
