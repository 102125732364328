/**
*
* EventsDiv
* Wrapper for events
*/

import styled from 'styled-components';


const EventsDiv = styled.div`

`;

export default EventsDiv;
