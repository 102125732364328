/* eslint-disable max-len */
/*
 * Members Messages
 *
 * This contains all the text for the Members container.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    defaultMessage: 'Members & Alums',
    id: 'app.containers.Members.header',
  },
});
