/**
*
* IntroWrapper
*
*/

import styled from 'styled-components';

const Intro = styled.section`
  text-align: center;
  align-content: center;
`;

export default Intro;
