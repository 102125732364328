/**
*
* A
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

// components
import A from '../A';

// local
import Wrapper from './Wrapper';
import messages from './messages';

/**
 * Fund link
 * @return {Object} The JSX for the Fund link
 */
function FundLink({ become, onSubmit }) {
  return (
    <Wrapper>
      <A href="/fund#apply" underline>
        <FormattedMessage {...messages.become} />
      </A>
    </Wrapper>
  );
}

FundLink.propTypes = {
  become: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default FundLink;
