/**
 * StyledLinks
 */

import styled from 'styled-components';

const StyledLinks = styled.span`
  display: block;
  margin: 3rem auto 2rem;
  text-align: center;
  font-size: .8em;
`;

export default StyledLinks;
