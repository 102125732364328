/**
*
* EventsPreview
*
* Preview events
*/

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

// components
import Col3 from '../../components/Col3';
import Event from '../../components/Event';
import Section from '../../components/Section';
import LoadingIndicator from '../../components/LoadingIndicator';
import Line from '../../components/Line';
import ErrorMessage from '../../components/ErrorMessage';

// local
import EventsDiv from './EventsDiv';
import StyledH2 from './StyledH2';

/**
 * Preview events
 * @return {Function} The JSX component for a EventsPreview
 */
function EventsPreview({
  events, header, error, loading, showDate,
}) {
  let content;

  // Loading members from backend
  if (loading) content = <LoadingIndicator />;

  // An error occurred while loading members
  else if (error) content = <ErrorMessage >{error.message}</ErrorMessage>;

  // Members were loaded
  else {
    content = (
      <EventsDiv>
        {events.map((event, ind) => (
          <Col3 key={event.Name} noLeftMargin={ind % 3 === 0} noRightMargin={ind % 3 === 2}>
            <Event {...event} showDate={showDate} />
          </Col3>
        ))}
      </EventsDiv>
    );
  }

  return (
    <Section id="events" paddingBottom="false">
      <StyledH2 marginTop marginBottom><FormattedMessage {...header} /></StyledH2>
      <Line padding="0 0 30px 0" />
      {content}
      <Line padding="30px 0 0 0" />
    </Section>
  );
}

EventsPreview.defaultProps = {
  loading: false,
  showDate: false,
};

EventsPreview.propTypes = {
  error: PropTypes.object,
  /** The events to display */
  events: PropTypes.array.isRequired,
  /** The header message */
  header: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  /** Whether to show the date */
  showDate: PropTypes.bool,
};

export default EventsPreview;
