/**
*
* FAQ
*
* Displays a single Q&A
*/

import Collapse from 'rc-collapse';
import styled from 'styled-components';

export const CollapseHeadline = styled.h4`
  cursor: pointer;
  &:hover, &:active {
    color: ${(props) => props.theme.gold};
  }

  &:before {
    content: "+";
    margin-left: -1em;
    margin-right: 0.4em;
  }

  .rc-collapse-item-active &:before {
    content: "–";
  }
`;
export const StyledCollapse = styled(Collapse)`
  margin-bottom: 20px;

  & .rc-collapse-header:focus {
    outline: none;
  }
`

export const StyledAnswer= styled.p`
  white-space: pre-line;
  margin-bottom: 40px;
`
