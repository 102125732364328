/**
*
* TextContainer
* Wraps tex
*/

import styled from 'styled-components';


const TextContainer = styled.div`

  @media (min-width: 768px) {
    position: relative;

    :after {
        content: "";
        position: absolute;
        left: 0;
        z-index: 100;
        bottom: 0;
        width: 1px;
        height: 26%;
        background: blue;
    }
  }
`;

export default TextContainer;
