/**
*
* Members
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { FormattedMessage } from 'react-intl';
import { shuffle } from 'lodash';

// components
import H2 from '../../components/H2';
import Member from '../../components/Member';
import Section from '../../components/Section';
import LoadingIndicator from '../../components/LoadingIndicator';
import Line from '../../components/Line';
import ErrorMessage from '../../components/ErrorMessage';
import InnerDiv from '../../components/InnerDiv';

// local
import MembersDiv from './MembersDiv';
import FadeCover from './FadeCover';
import Arrow from './Arrow';
import messages from './messages';


class Members extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      members: shuffle([
        ...(props.data.members || []),
        ...(props.data.alumni || []),
      ]),
    };
  }

  state = { collapsed: true }

  componentWillReceiveProps(newProps) {
    const { data } = this.props;
    if (data.members !== newProps.data.members) {
      this.setState({
        members: shuffle([
          ...(newProps.data.members || []),
          ...(newProps.data.alumni || []),
        ]),
      });
    }
  }

  render() {
    const { collapsed, members } = this.state;
    const { data: { loading, error } } = this.props;
    let content;

    // Loading members from backend
    if (loading) content = <LoadingIndicator />;

    // An error occurred while loading members
    else if (error) content = <ErrorMessage>{error.message}</ErrorMessage>;

    // Members were loaded
    else if (Object.values(members).length > 0) {
      content = (
        <Section id="members">
          <H2><FormattedMessage {...messages.header} /></H2>
          <Line />
          <MembersDiv isCollapsed={collapsed}>
            {members.map((member) => (
              <Member key={member.image_192} {...member} modal />
            ))}
            <Arrow isCollapsed={collapsed} onClick={() => this.setState({ collapsed: !this.state.collapsed })} />
            <FadeCover isCollapsed={collapsed} />
          </MembersDiv>
          <Line />
          <div style={{ height: '30px' }} />
          <InnerDiv height="120px" right />
        </Section>
      );
    }

    return (
      <div>
        {content}
      </div>
    );
  }
}

Members.propTypes = {
  /** The graphql request for members */
  data: PropTypes.object.isRequired,
};

export default graphql(gql`
  query MembersQuery {
    members {
      id
      title
      real_name
      first_name
      last_name
      image_24
      image_32
      image_48
      image_72
      image_192
      image_512
      image_1024
      image_original
      is_alum
      bio
      current_focus
    }
    alumni {
      id
      title
      real_name
      first_name
      last_name
      image_24
      image_32
      image_48
      image_72
      image_192
      image_512
      image_1024
      image_original
      is_alum
      bio
      current_focus
    }
  }
`)(Members);
