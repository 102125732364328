/**
*
* BigText
*
*/

import styled from 'styled-components';

const BigText = styled.span`
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
  display: block;
  font-weight: 500;
  text-align: ${(props) => props.alignLeftMobile ? 'left' : 'center'};
  ${(props) => props.color ? `color: ${props.color}` : ''};

  @media (min-width: 768px) {
    text-align: center;
    font-size: 24px;
    line-height: 32px;
  }

  @media (min-width: 992px) {
    text-align: center;
    font-size: 36px;
    line-height: 46px;
  }
`;

export default BigText;
