/**
*
* Header
*
*/

import React from 'react';
// import PropTypes from 'prop-types';

// components
import Nav from '../Nav';
import Logo from '../Logo';
import Wrapper from './Wrapper';

// local

/**
 * The Header component
 * @return {Function} The JSX component for a Header
 */
function Header() {
  return (
    <Wrapper>
      <Logo  />
      <Nav />
    </Wrapper>
  );
}

Header.propTypes = {
};

export default Header;
