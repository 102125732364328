/**
 * ImageWrapper
 */

import styled from 'styled-components';

const ImageWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
`;

export default ImageWrapper;
