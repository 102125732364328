/**
*
* ErrorMessage
*
*/

import styled from 'styled-components';

// components
import Col1 from '../Col1';

const ErrorMessage = styled(Col1)`
  text-align:center;
  color: ${(props) => props.theme.error};
  font-weight: bold;
  text-align: center;
`;

export default ErrorMessage;
