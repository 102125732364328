/**
*
* Companies
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { FormattedMessage } from 'react-intl';

// components
import H2 from '../../components/H2';
import Section from '../../components/Section';
import Company from '../../components/Company';
import LoadingIndicator from '../../components/LoadingIndicator';
import ErrorMessage from '../../components/ErrorMessage';
import Line from '../../components/Line';
import InnerDiv from '../../components/InnerDiv';

// local
import CompaniesDiv from './CompaniesDiv';
import InfoWrapper from './InfoWrapper';
import messages from './messages';

class Companies extends React.Component {
  state = { hidden: true }

  render() {
    const { hidden } = this.state;
    const { data: { loading, companies, error } } = this.props;
    let content;

    // Loading testimonials from backend
    if (loading) content = <LoadingIndicator />;
    // An error occurred while loading testimonials
    else if (error) content = <ErrorMessage >{error.message}</ErrorMessage>;
    // Testimonials were loaded
    else if (Object.values(companies).length > 0) {
      content = (
        <Section id="companies">
          <H2 selected={!hidden}>
            <FormattedMessage {...messages.header} />
          </H2>
          <InfoWrapper><FormattedMessage {...messages.info} /></InfoWrapper>
          <Line />
          <CompaniesDiv>
            {companies.map((company) => (
              <Company key={company.Name} {...company} />
            ))}
          </CompaniesDiv>
          <Line />
          <div style={{ height: '30px' }} />
          <InnerDiv height="120px" right />
        </Section>
      );
    }

    return (
      <div>
        {content}
      </div>
    );
  }
}

Companies.propTypes = {
  /** The graphql request data for testimonials */
  data: PropTypes.object.isRequired,
};

export default graphql(gql`
  query TestimonialsQuery {
    companies {
      Description,
      Website,
      SlackMemberID_1,
      SlackMemberID_2,
      SlackMemberID_3,
      SlackMemberID_4,
      Logo,
      Name,
    }
  }
`)(Companies);
