/**
*
* Img
* An img
*/

import styled from 'styled-components';


const Img = styled.img`
display: ${(props) => props.noMobile ? 'none' : 'inline'};

@media only screen and (min-width: 768px) {
  display: inline;
})
`;

export default Img;
