/**
*
* Explore
*
* Explore SPC
*/

import React from 'react';


// components
import Col3 from '../Col3';
import Line from '../Line';
import Preview from '../Preview';

// local
import community from './community.jpg';
import discover from './discover.jpg';
import work from './workfrom.jpg';
import messages from './messages';
import Wrapper from './Wrapper';

/**
 * Explore SPC
 * @return {Function} The JSX component for a Explore
 */
function Explore() {
  return (
    <Wrapper>
      <Line padding="10px 0" />
      <Col3 noLeftMargin>
        <Preview
          header={messages.community}
          img={community}
          href="/community"
        />
      </Col3>
      <Col3>
        <Preview
          header={messages.discover}
          img={discover}
          href="/events"
        />
      </Col3>
      <Col3 noRightMargin>
        <Preview
          header={messages.work}
          img={work}
          href="/apply"
        />
      </Col3>

    </Wrapper>
  );
}


Explore.propTypes = {
};

export default Explore;
