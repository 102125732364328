/**
*
* ArrowWrapper
* Wrapper for arrow img
*/

import styled from 'styled-components';


const ArrowWrapper = styled.div`
  display: none;

  @media (min-width: 768px) {
    top: -270px;
    right: 0;
    position: relative;
    text-align: right;
    height: 0;
    display: block;
  }

  @media (min-width: 992px) {
    top: -550px;
    right: -80px;
  }
`;

export default ArrowWrapper;
