/**
 * FadeCover
 */
import styled from 'styled-components';

const FadeCover = styled.div`
  background: -webkit-linear-gradient(rgba(255,255,255,0.5),rgba(255,255,255,1));
  background: linear-gradient(to top, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 20%,rgba(255,255,255,0) 100%);
  height: 300px;
  width: 100%;
  display: ${(props) => props.hidden ? 'block' : 'none'};
  position: absolute;
  bottom: 0;
  z-index: 1;
  left: 0;
`;

export default FadeCover;
