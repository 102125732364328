/**
*
* Fund Member
*
* Displays a Fund Member
*/

import React from 'react';
import PropTypes from 'prop-types';

// components

// local
import Wrapper from './Wrapper';
import ImageWrapper from './ImageWrapper';
import SummaryWrapper from './SummaryWrapper';
import NameWrapper from './NameWrapper';
import Blank from './blank.png';

class FundMember extends React.Component { // eslint-disable-line
  render() {
    const {
      MemberPhoto, Name, Summary1, Summary2,
    } = this.props;
    return (
      <div>
        <Wrapper>
          <ImageWrapper>
            <img align="middle" src={MemberPhoto || Blank} alt="main-member" />
          </ImageWrapper>
          <NameWrapper>
            {Name}
          </NameWrapper>
          <SummaryWrapper>
            {Summary1}
          </SummaryWrapper>
          <SummaryWrapper>
            {Summary2}
          </SummaryWrapper>
        </Wrapper>
      </div>
    );
  }
}

FundMember.defaultProps = {
};

FundMember.propTypes = {
  /** The name of the FundMember */
  Name: PropTypes.string.isRequired,
  /** The Summary1 of the FundMember */
  Summary1: PropTypes.string,
  /** The Summary2 of the FundMember */
  Summary2: PropTypes.string,
  /** The MemberPhoto of the FundMember */
  MemberPhoto: PropTypes.string,
};

export default FundMember;
