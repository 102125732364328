/**
*
* Home
*
*/

import React from 'react';
import { FormattedMessage } from 'react-intl';

// components
import ApplyLink from '../../components/ApplyLink';
import Explore from '../../components/Explore';
import Intro from '../../components/Intro';
import BigText from '../../components/BigText';
import UpArrow from '../../components/UpArrow';
import OuterDiv from '../../components/OuterDiv';
import InnerDiv from '../../components/InnerDiv';
import StyledText from '../../components/StyledText';
import H3 from '../../components/H3';
import PageFooter from '../../components/PageFooter';
import ImgFull from '../../components/ImgFull';
import Line from '../../components/Line';
import Values from '../../components/Values';

// local
import messages from './messages';
import Wrapper from './Wrapper';
import InnerWrapper from './InnerWrapper';
import design1 from './design1.png';
import design2 from './design2.png';
import design3 from './design3.png';

/**
 * The Home component
 * @return {Function} The JSX component for a Home
 */
function Home() {
  return (
    <Wrapper>
      <InnerWrapper>
        <Intro>
          <BigText>
            <div><FormattedMessage {...messages.header} /></div>
            <FormattedMessage {...messages.title} />
          </BigText>
        </Intro>
        <ApplyLink />
        <OuterDiv>
          <UpArrow />
          <InnerDiv bottom />
          <InnerDiv left noDesktop height="205px" />
          <InnerDiv left noMobile >
            <ImgFull height="386px" src={design1} alt="design-1" />
          </InnerDiv>
        </OuterDiv>
        <OuterDiv left>
          <InnerDiv full>
            <BigText width="665px" alignLeftMobile>
              <FormattedMessage {...messages.shots} />
            </BigText>
          </InnerDiv>
          <InnerDiv noDesktop full>
            <StyledText noIndent><FormattedMessage {...messages.together} /></StyledText>
            <StyledText noIndent><FormattedMessage {...messages.environment} /></StyledText>
          </InnerDiv>
        </OuterDiv>
        <OuterDiv noDesktop>
          <InnerDiv top height="100px" />
          <InnerDiv left height="100px" />
        </OuterDiv>
        <OuterDiv noMobile>
          <InnerDiv top right>
            <ImgFull height="398px" src={design2} alt="design-2" />
          </InnerDiv>
          <InnerDiv>
            <StyledText noIndent><FormattedMessage {...messages.together} /></StyledText>
            <StyledText noIndent><FormattedMessage {...messages.environment} /></StyledText>
          </InnerDiv>
        </OuterDiv>
      </InnerWrapper>
      <Values />
      <Line padding="0 0 25px 0" />
      <InnerWrapper>
        <OuterDiv noDesktop>
          <InnerDiv bottom />
          <InnerDiv left height="205px" />
          <InnerDiv left full>
            <H3 justify="left"><FormattedMessage {...messages.exploreHeader} /></H3>
            <StyledText noIndent><FormattedMessage {...messages.exploreDescription} /></StyledText>
            <H3 justify="left"><FormattedMessage {...messages.learnHeader} /></H3>
            <StyledText noIndent><FormattedMessage {...messages.learnDescription} /></StyledText>
            <H3 justify="left"><FormattedMessage {...messages.launchHeader} /></H3>
            <StyledText noIndent><FormattedMessage {...messages.launchDescription} /></StyledText>
          </InnerDiv>
          <InnerDiv top right />
          <InnerDiv />
        </OuterDiv>
        <OuterDiv noMobile>
          <InnerDiv right>
            <H3 justify="left"><FormattedMessage {...messages.exploreHeader} /></H3>
            <StyledText noIndent><FormattedMessage {...messages.exploreDescription} /></StyledText>
            <H3 justify="left"><FormattedMessage {...messages.learnHeader} /></H3>
            <StyledText noIndent><FormattedMessage {...messages.learnDescription} /></StyledText>
            <H3 justify="left"><FormattedMessage {...messages.launchHeader} /></H3>
            <StyledText noIndent><FormattedMessage {...messages.launchDescription} /></StyledText>
          </InnerDiv>
          <InnerDiv>
            <ImgFull height="398px" src={design3} alt="design-3" />
          </InnerDiv>
        </OuterDiv>
      </InnerWrapper>
      <Explore />
      <PageFooter noTop />
    </Wrapper>
  );
}

Home.propTypes = {
};

export default Home;
