/**
 *
 * SummerFAQ
 *
 * SummerFAQ questions and answers
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';

// components
import H2 from '../../components/H2';
import PageFooter from '../../components/PageFooter';

// containers
import SummerFAQs from '../SummerFAQs';

// utils

// local
import messages from './messages';
import Wrapper from './Wrapper';

/**
 * The SummerFAQ component
 * @return {Function} The JSX component for SummerFAQ page
 */
export function SummerFAQ() {
  return (
    <Wrapper>
      <H2 style={{color: "#000000"}}><FormattedMessage {...messages.header} /></H2>
      <SummerFAQs />
      <PageFooter noTop noApply noCenterLine noDot />
    </Wrapper>
  );
}


SummerFAQ.propTypes = {
};

export default SummerFAQ;
