/**
*
* TeamMember
*
*/

import styled from 'styled-components';

const ImageWrapper = styled.div`
    width: 96px;
    height: 96px;
    border-radius: 54px;
    overflow: hidden;
    display: block;
    margin: 0 auto;
    float: left;
    img {
        width: 100%;
    }

    @media (max-width: 850px) {
        float: inherit;
    };
`;

export default ImageWrapper;
