/**
*
* NavLinkWrapper
*
*/
import styled from 'styled-components';

// components
import { Link } from 'react-router-dom';

const LinkWrapper = styled(Link)`
  color: ${(props) => props.for_footer ? props.theme.gold : (props.selected ? props.theme.gold : props.theme.text)};
  font-size: ${(props) => props.for_footer ? '1rem' : '2rem' };
  font-weight: light;
  text-decoration: none;

  @media (min-width: 768px) {
    color: ${(props) => props.selected ? props.theme.blue : props.theme.gold};
    font-size: 1rem;
    padding: 10px 15px;
  }
  &:hover, &:active {
    text-decoration: underline;
  }
`;

export default LinkWrapper;
