/**
*
* Li
*
*/

import styled from 'styled-components';

const Li = styled.li`
  padding: 15px 10px;

  @media (min-width: 768px) {
    float: left;
    text-align: center;
    padding: 0;
  }
`;

export default Li;
