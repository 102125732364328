/* eslint-disable max-len */
/*
 * Community Messages
 *
 * This contains all the text for the Community container.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    defaultMessage: 'Frequently Asked Questions',
    id: 'app.containers.FAQ.header',
  },
});
