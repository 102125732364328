/**
*
* NavWrapper
*
*/

import styled from 'styled-components';

const Wrapper = styled.div`
  color: ${(props) => props.theme.gold};
  padding-top: 30px;
  padding-bottom: 35px;
  border: 1px solid transparent;
  overflow: hidden;
  transition: max-height 0.25s ease-out;
  ${(props) => !props.isHidden && `
    max-height: 400px;
    transition: max-height 0.25s ease-in;
  `}

  @media (min-width: 768px) {
    padding-bottom: 60px;
  }
`;

export default Wrapper;
