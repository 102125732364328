/**
 * StyledImg
 */

import styled from 'styled-components';

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
`;

export default StyledImg;
