/**
*
* CollapseWrapper
*
*/

import styled from 'styled-components';

const CollapseWrapper = styled.nav`
  float: right;
  padding-right: 20px;

  @media (min-width: 768px) {
    padding-right: 0;
  }
`;

export default CollapseWrapper;
