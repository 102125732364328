/**
*
* UpArrow
* An up arrow
*/

import styled from 'styled-components';


const UpArrow = styled.div`
  height: 10px;
  left:calc(50% - 5px);
  top:-30px;
  border-left:1px solid blue;
  border-bottom:1px solid blue;
  position: absolute;
  transform: rotate(-45deg);
  width: 10px;
`;

export default UpArrow;
