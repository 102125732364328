/* eslint-disable max-len */
/*
 * Companies Messages
 *
 * This contains all the text for the Companies container.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    defaultMessage: 'Companies',
    id: 'app.containers.Companies.header',
  },
  info: {
    defaultMessage: 'Founded by South Park Commons Members',
    id: 'app.containers.Companies.info',
  },
});
