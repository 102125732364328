/**
*
* Bar
*
*/

import styled from 'styled-components';

const Bar = styled.div`
  width: 25px;
  height: 2px;
  background-color: ${(props) => props.theme.gold};
  margin: 5px 0;
`;

export default Bar;
