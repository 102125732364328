/* eslint-disable max-len */
/*
 * Explore Messages
 *
 * This contains all the text for the Explore component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    defaultMessage: 'Explore',
    id: 'app.components.Explore.header',
  },
  community: {
    defaultMessage: 'Meet the community',
    id: 'app.containers.Explore.community',
  },
  discover: {
    defaultMessage: 'View our events',
    id: 'app.containers.Explore.discover',
  },
  work: {
    defaultMessage: 'News from SPC',
    id: 'app.containers.Explore.work',
  },
});
