/**
*
* Section
*
*/

import styled from 'styled-components';

const Section = styled.section`
  padding-top: 15px;
  ${(props) => props.paddingBottom !== 'false' ? 'padding-bottom: 30px;' : ''};
`;

export default Section;
