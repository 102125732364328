/**
*
* Line
*/

import styled from 'styled-components';


const Line = styled.div`
  box-sizing: border-box;
  width: ${(props) => props.half ? '50%' : '100%'};
  height: 1px;
  border-top: 1px solid blue;
  padding: ${(props) => props.padding ? props.padding : ''};
`;

export default Line;
