/**
*
* Wrapper
*
*/

import styled from 'styled-components';

const Wrapper = styled.form`
  padding-top: 40px;
  position: relative;
  max-height: none;
  opacity: 1;
  margin: 0 auto;
  width: 100%;

  @media (min-width: 768px) {
    width: 300px;
  }
`;

export default Wrapper;
