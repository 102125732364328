/**
*
* Dot
* A dot
*/

import styled from 'styled-components';


const Dot = styled.div`
  background: ${(props) => props.theme.blue};
  width: 6px;
  height: 6px;
  border-radius: 3px;
  margin: ${(props) => props.margin};
  transform: translate(-3.5px, 0);
`;

export default Dot;
