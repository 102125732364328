/**
 * Validate
 */

import styled from 'styled-components';

const Validate = styled.span`
  position: absolute;
  top: -7px;
  right: 0px;
  color: rgb(213, 122, 122);
  font-size: 1.5rem;
  opacity:0;
  transition:all 0.4s linear;
  ${(props) => !props.isValid && 'opacity: 1; transform: translateY(15px);'};
`;

export default Validate;
