/**
*
* Press
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { FormattedMessage } from 'react-intl';

// components
import A from '../../components/A';
import Section from '../../components/Section';
import StyledText from '../../components/StyledText';
import BigText from '../../components/BigText';
import OuterDiv from '../../components/OuterDiv';
import InnerDiv from '../../components/InnerDiv';

// local
import messages from './messages';
import PressCover from './PressCover';
import NYTlogo from './Symbol-New-York-Times-small.png';
import Forbeslogo from './forbes-f_1600x1600-small.jpg';
import Dot from './Dot';

class Press extends React.Component {
    state = { hidden: true }

    render() {
      const content = (
        <Section paddingBottom="false" id="press">
          <BigText color="#DCA55E">
            <FormattedMessage {...messages.header} />
          </BigText>
          <div style={{ height: '25px' }}></div>
          {/* <Line /> */}
          <OuterDiv noMobile>
            <InnerDiv height="false" padding="false" />
            <InnerDiv padding="false" left bottom />
          </OuterDiv>
          <OuterDiv bottom noDesktop></OuterDiv>
          <OuterDiv noMobile right>
            <div style={{ height: '25px' }}></div>
            <PressCover>
              <div>
                <StyledText>
                  <A href="https://www.forbes.com/sites/alexkonrad/2018/04/26/south-park-commons-raises-fund/">
                    <img src={Forbeslogo} alt="Forbes article SPC" />
                    <FormattedMessage {...messages.article1} />
                  </A>
                </StyledText>
              </div>
              <div style={{ height: '15px' }}></div>
              <div>
                <StyledText>
                  <A href="https://www.nytimes.com/2017/07/02/business/tech-scene-south-park-commons-san-francisco.html">
                    <img src={NYTlogo} style={{ marginRight: '15px' }} alt="NYTimes article SPC" />
                    <FormattedMessage {...messages.article2} />
                  </A>
                </StyledText>
              </div>
            </PressCover>
            <InnerDiv padding="false"  />
            <InnerDiv padding="false" />
          </OuterDiv>
          <OuterDiv noMobile>
            <InnerDiv padding="false" />
            <InnerDiv top left />
            <Dot margin="0 50.1%" />
          </OuterDiv>

          <OuterDiv noDesktop>
            <div style={{ height: '25px' }}></div>
            <PressCover>
              <div>
                <StyledText>
                  <A href="https://www.forbes.com/sites/alexkonrad/2018/04/26/south-park-commons-raises-fund/">
                    <img src={Forbeslogo} alt="Forbes article SPC" />
                    <FormattedMessage {...messages.article1} />
                  </A>
                </StyledText>
              </div>
              <div style={{ height: '15px' }}></div>
              <div>
                <StyledText>
                  <A href="https://www.nytimes.com/2017/07/02/business/tech-scene-south-park-commons-san-francisco.html">
                    <img src={NYTlogo} style={{ marginRight: '15px' }} alt="NYTimes article SPC" />
                    <FormattedMessage {...messages.article2} />
                  </A>
                </StyledText>
              </div>
            </PressCover>
            <OuterDiv padding="false" bottom />
          </OuterDiv>
          <OuterDiv noDesktop style={{ marginTop: '10px', minHeight: '10px' }} >
            <InnerDiv padding="false" />
            <InnerDiv padding="false" left />
            <Dot margin="0 50.3%" />
          </OuterDiv>
        </Section>
      );

      return (
        <div>
          {content}
        </div>
      );
    }
}

Press.propTypes = {
  /** The graphql request data for testimonials */
  data: PropTypes.object.isRequired,
};

export default graphql(gql`
  query PressQuery {
    Press {
      Name,
    }
  }
`)(Press);
