/**
*
* MainWrapper
*
*/

import styled from 'styled-components';

const MainWrapper = styled.div`
    padding-bottom: 1.5rem;
    ${(props) => props.isFund ? 'color: white; background: black;' : ''}  
`;

export default MainWrapper;
