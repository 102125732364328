/**
 * StyledInput
 */

import styled from 'styled-components';

const StyledInput = styled.input`
  width: 100%;
  font-weight: 200;
  border: none;
  border-radius: 4px;
  outline: none;
  font-size: 10px;

  box-shadow:none;

  @media (min-width: 768px) {
    font-size: 15px;
  }
`;

export default StyledInput;
