/**
*
* NewsletterWrapper
*
*/

import styled from 'styled-components';

const Wrapper = styled.div`
  height: auto;
  margin: 0 auto;
  padding-top: 120px;
`;

export default Wrapper;
